.gallery-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    /* display: flex!important; */
    flex-direction: column;
    align-items: center;
    transition: all 0.3s ease-in-out;
    z-index: 9999;
    justify-content: center;
    backdrop-filter: blur(6.9px);
    -webkit-backdrop-filter: blur(6.9px);
    height: 100%;
    width: 100%;
}

.gallery-modal-wrapper .gallery-viewer-wrapper {
    width: 95%;
    height: 95%;
    background-color: #e7e6e6;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    transition: all 0.4s ease-in-out;
    border-radius: 4px;
}

 .close {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
 .close:after, .close:before {
    content: "";
    height: 20px;
    width: 20px;
    border-top: 1px solid #000;
    position: absolute;
    top: 20px;
    right: 3px;
    transform: rotate(-45deg);
}
 .close.zoom-close:after, .close.zoom-close:before{
    border-top: 1px solid #fff;
}

 .close:before {
    right: 17px;
    transform: rotate(45deg);
}
 .close:hover{opacity: 0.3;}




.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-header-wrapper{
    height: 9%;

}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-header-wrapper .gallery-header-inner-wrapper {
    padding: 5px;
    border-bottom: 1px solid #cbcaca;
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-header-wrapper .gallery-header-inner-wrapper span{
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 4px;
    /* border-bottom: 3px solid #00B5FF; */
    padding: 0 7px;
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-header-wrapper .gallery-header-inner-wrapper button.active{
    color: #3f3f3f;
    /* border: 1px solid #000; */
    border: 2px solid #00B5FF;

}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-header-wrapper .gallery-header-inner-wrapper button {
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    color: #676767;
    cursor: pointer;
    display: inline-flex;
    flex-grow: 1;
    font-size: .875rem;
    font-weight: 400;
    justify-content: center;
    line-height: 1;
    padding: 0.8rem;
    text-align: center;
    font-weight: 700;
    border: 1px solid #676767;
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-header-wrapper .gallery-header-inner-wrapper button:not(:first-child){
    margin: 0 5px;
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper {
    display: flex;
    height: 91%;
    padding: 5px 0;
}
/* .gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper img{
    border-radius: 5px;
} */

.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper  .gallery-pane-container {
    width: 20%;
    height: 100%;
    transition: all 0.2s ease-in-out;
    /* border-right: 1px dashed #c5c5c5; */
}
.product-media-wrapper .gallery-pane-container{
    width: 100%;
}

.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-pane-container .gallery-pane-inner-container, .product-media-wrapper .gallery-pane-container .gallery-pane-inner-container{
    overflow-y: scroll;
    max-height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    grid-row-gap: 5px;
    scroll-behavior: smooth;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-mask-image: linear-gradient(to top, transparent 2px, #000 20px);
    mask-image: linear-gradient(to top, transparent 2px, #000 20px);
    padding: 0 5px 20px;
    scrollbar-width: thin;
    user-select: none;
}


/* .gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-pane-container .gallery-pane-inner-container */



/* width */
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-pane-container .gallery-pane-inner-container::-webkit-scrollbar {
    width: 5px;
    transition: all 0.2s ease-in-out;
    /* display: none; */
}

/* Track */
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-pane-container .gallery-pane-inner-container::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-pane-container:hover .gallery-pane-inner-container::-webkit-scrollbar-thumb {
    background: #cfcbcb;
    border-radius: 5px;
    display: block;
}

/* Handle on hover */
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-pane-container:hover .gallery-pane-inner-container::-webkit-scrollbar-thumb {
    background: #b0aeae;
    /* display: block; */
}

/* .gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-pane-container .gallery-pane-inner-container .gallery-pane-image-wrapper {
    margin: 5px;
} */

.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-pane-container .gallery-pane-inner-container .gallery-pane-image-wrapper.single-image{
    width: 100%;
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-pane-container .gallery-pane-inner-container .gallery-pane-image-wrapper.two-images{
    width: 50%;
    padding: 2px;
}

.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-pane-container .gallery-pane-inner-container .gallery-pane-image-wrapper .pane-image {
    border: 3px solid #e7e6e6;
    display: flex;
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
}

.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-pane-container .gallery-pane-inner-container .gallery-pane-image-wrapper.active .pane-image {
    border: 3px solid #00B5FF;
    pointer-events: none;
    opacity: 0.8;
    user-select: none;
}

.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-full-image-container{
    width:80%;
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-full-image-container .gallery-manfacture-logo-wrapper {
    height: 6%;
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-full-image-container .gallery-manfacture-logo-wrapper .gallery-manfacture-logo-inner-wrapper{
    height:100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* padding: 0 10px; */
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-full-image-container .gallery-manfacture-logo-wrapper .gallery-manfacture-logo-inner-wrapper img {
    height: 100%;
    max-width: 250px;
    width: auto;
    object-fit: contain;
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-full-image-container .gallery-full-image-inner-container {
    height: 94%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper :is(.gallery-full-image-container .gallery-full-image-inner-container, .full-view-document) .slider-action {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper :is(.gallery-full-image-container .gallery-full-image-inner-container, .full-view-document) .slider-action.prev-slide {
    left: 0%;
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper :is(.gallery-full-image-container .gallery-full-image-inner-container, .full-view-document) .slider-action.next-slide {
    right: 0;
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper :is(.gallery-full-image-container .gallery-full-image-inner-container, .full-view-document) .slider-action button{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    width: 2rem;
    background-color: #fff;
    border-color: #e6e6e6;
    opacity: 1;
    overflow: visible;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 1px 2px 0px rgba(0,0,0,0.14), 2px 1px 5px 0px rgba(0,0,0,0.12);
}

.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-full-image-container .gallery-full-image-inner-container .full-image {
    width: 100%;
    max-height: 100%;
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
    overflow: hidden;
    height: 100%;
    display: flex;
    justify-content: center;
    user-select: none;
    padding: 5px 0;
}


.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-full-image-container .gallery-full-image-inner-container .full-image img {
    border-radius: 5px;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    transition: all .4s ease-in-out;
    width: auto;
    /* filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.2)); */
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-full-image-container :is(.full-view-document, .full-view-video),
.product-media-wrapper .full-view-video
{
    height: 94%;
    width: 98%;
    margin-left: auto;
    padding-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.product-media-wrapper .full-view-video{
    height: 60vh;
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-full-image-container .full-view-document iframe{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-full-image-container .gallery-full-image-inner-container.no-mrf{
    height: 100%;
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-full-image-container :is(.full-view-document, .full-view-video).no-mrf{
    height: 100%;
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-full-image-container .full-view-video video,
.product-media-wrapper .full-view-video video{
    max-height: 100%;
    height: auto;
    border-radius: 6px;
    width: 100%;
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-full-image-container .full-view-video video:focus-visible {
    outline: 0px;
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-full-image-container  .count-container {
    position: absolute;
    bottom: 10px;
    right: 3%;
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-full-image-container  .count-container span {
    background-color: #2d2b2bcf;
    color: #fff;
    border-radius: 20px;
    padding: 6px 10px;
    font-size: 12px;
    font-weight: 600;
}

.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-pane-container .gallery-pane-inner-container .single-pane-pdf-wrapper {
    width: 95%;
    height: 15rem;
    margin: 5px 0px;
    border-radius:5px;
    position: relative;
    border: 3px solid transparent;
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-pane-container .gallery-pane-inner-container .single-pane-pdf-wrapper .pdf-select-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 95%;
    height: 100%;
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-pane-container .gallery-pane-inner-container .single-pane-pdf-wrapper.active{
    border: 3px solid #00B5FF;
    pointer-events: none;
    opacity: 0.8;
    -webkit-user-select: none;
    user-select: none;
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-pane-container .gallery-pane-inner-container .single-pane-pdf-wrapper iframe{
    width: 100%;
    height: 100%;
}

.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-pane-container .gallery-pane-inner-container .single-pane-video-wrapper ,.product-media-wrapper .gallery-pane-container .gallery-pane-inner-container .single-pane-video-wrapper{
    width: 100%;
    border-radius:5px;
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-pane-container .gallery-pane-inner-container .single-pane-video-wrapper.active,
.product-media-wrapper .gallery-pane-container .gallery-pane-inner-container .single-pane-video-wrapper.active
{
    border: 3px solid #00B5FF;
    pointer-events: none;
    opacity: 0.8;
    -webkit-user-select: none;
    user-select: none;
}
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-pane-container .gallery-pane-inner-container .single-pane-video-wrapper video,
.product-media-wrapper .gallery-pane-container .gallery-pane-inner-container .single-pane-video-wrapper video
{
    width: 100%;
    height: 100%;
    background-color: #ddd;
}










/* 
.gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-full-image-container {
    width: 100%;
    height: 100%;
} */




/* .pane-images {
    width: 100%;
    margin: 3px 0;
} */

.single-image img {
    width: 100%;
    height: 9rem;
    max-height: 12rem;
    min-height: 100px;
    object-fit: cover;
    background-color: #fff;
}

/* .two-images {
    display: flex;
    gap: 5px;
    width: 100%;
} */

.two-images img {
    width: 100%;
    height: 6rem;
    max-height: 9rem;
    object-fit: cover;
    min-height: 50px;
    background-color: #fff;
}





@media(max-width:991px){
    .gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-full-image-container{
        width: 100%;
    }
    .gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-pane-container{
        display: none;
    }
    .gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-full-image-container .gallery-full-image-inner-container .full-image{
        height: 100%;
        width: auto;
        max-width: 100%;
    }
    
}

@media (max-width:1100px){
    .gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-pane-container .gallery-pane-inner-container .gallery-pane-image-wrapper.two-images {
        width: 100%;
    }
    .gallery-modal-wrapper .gallery-viewer-wrapper .gallery-viewer-inner-wrapper .gallery-pane-container .gallery-pane-inner-container .gallery-pane-image-wrapper.two-images img{
        max-height: 125px;
    }
    :is(.two-images,.single-image) img{
        height: 8rem;
    }
}


@media (min-width: 1060px) {
    .gallery-modal-wrapper .gallery-viewer-wrapper {
        width: min(1882px, calc(100vw - 4rem));
        max-height: calc(100vh - 4rem);
        /* border-radius: 8px; */
    }
}




/* 
    -webkit-mask-image: linear-gradient(180deg,transparent 0,#000 3rem calc(100% - 3rem),transparent);
    mask-image: linear-gradient(180deg,transparent 0,#000 3rem calc(100% - 3rem),transparent);
*/