header.header {
  background-color: var(--navbar-bg);
  /* background-color: transparent; */
  /* background-color: rgba(0,0,0,0.05); */
  color: var(--navbar-text-light);
  padding: 10px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  transition: all 0.3s ease-in-out;
}
header.header.active{
  background-color: var(--navbar-bg);
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  transition: all 0.3s ease-in-out;
}
header.header nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header.header .logo-wrapper{width: 250px;transition: all 0.2s ease-in-out;}
header.header .logo-wrapper img{width: 100%; transition: all 0.2s ease-in-out;}
header.header.active .logo-wrapper {width: 200px;}

header.header nav .menu-icon{display: none;}

header.header nav .menu-icon .hamburger-wrapper .hamburger .line{
  width: 25px;
  height: 3px;
  background-color: #ecf0f1;
  display: block;
  margin: 4px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
header.header nav .menu-icon .hamburger-wrapper .hamburger{
  z-index: 99;
  position: relative;
}
header.header nav .menu-icon .hamburger-wrapper .hamburger:hover{
  cursor: pointer;
}

header.header nav .menu-icon .hamburger-wrapper .hamburger.is-active .line:nth-child(2){
  opacity: 0;
}

header.header nav .menu-icon .hamburger-wrapper .hamburger.is-active .line:nth-child(1){
  -webkit-transform: translateY(5px) rotate(45deg);
  -ms-transform: translateY(5px) rotate(45deg);
  -o-transform: translateY(5px) rotate(45deg);
  transform: translateY(5px) rotate(45deg);
}

header.header nav .menu-icon .hamburger-wrapper .hamburger.is-active .line:nth-child(3){
  -webkit-transform: translateY(-8px) rotate(-45deg);
  -ms-transform: translateY(-8px) rotate(-45deg);
  -o-transform: translateY(-8px) rotate(-45deg);
  transform: translateY(-8px) rotate(-45deg);
}

header.header nav .menu-icon .hamburger-wrapper aside{position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0,0,0,0.6);
  transition: all 0.7s ease-in-out;
  display: none;
}
header.header nav .menu-icon .hamburger-wrapper aside ul{
  padding-top: 100px;
  width: 30%;
  height: 100%;
  background-color: #000;
  list-style: none;
}
header.header nav .menu-icon .hamburger-wrapper aside.show-side-menu{display: block;z-index: 9;display: flex;transition: all 0.7s ease-in-out;}
header.header nav .menu-icon .hamburger-wrapper aside ul{transform: translate(-300px,0); transition: all 0.7s ease-in-out;}
header.header nav .menu-icon .hamburger-wrapper aside.show-side-menu ul{
  transform: translate(0);
  transition: all 0.7s ease-in-out;
}
header.header nav .menu-icon .hamburger-wrapper aside ul li a{
 
  text-decoration: none;
  color: var(--navbar-text-light);
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-weight: 600;
  display: inline-block;
  padding: 10px;
  position: relative;
}
header.header nav ul.main-nav{display: flex;
  gap: 15px;
  list-style: none;
  align-items: center;
  margin: 0;
  padding: 0;
}
header.header nav :is(.menu-icon, .add-to-cart-nav) i{cursor: pointer;font-size: 20px;}
header.header nav ul.main-nav li a{
  text-decoration: none;
  color: var(--navbar-text-light);
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-weight: 600;
  display: inline-block;
  padding: 5px 10px;
  position: relative;
}
header.header nav :is(ul.main-nav li, .hamburger-wrapper aside ul li) a:after{
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  position: absolute;
  background: var(--nav-active-hover);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
header.header nav :is(ul.main-nav li, .hamburger-wrapper aside ul li) a:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: var(--nav-active-hover);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
header.header nav :is(ul.main-nav li, .hamburger-wrapper aside ul li) :is(a:hover,a.active):after { 
  width: 100%; 
  left: 0; 
}
header.header nav .menu-icon .hamburger-wrapper aside .hamburger-close-wrapper{    
  width: 70%;
  background-color: rgba(0,0,0,0.6);
  backdrop-filter: blur(6.9px);
  -webkit-backdrop-filter: blur(6.9px)
}
header.header nav .add-to-cart-nav .nav-cart-button-wrapper{display: flex;align-items: center;}
.cart-list-count-wrapper{
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.9rem;
  color: #fff;
  background: linear-gradient(to right, var(--button-bg) 0%, #2c64fc 100%);
  /* padding: 2px 8px; */
  /* border: 2px solid #fff; */
  border-radius: 50%;
  width: 1.1rem;
  height: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(8px, -7px);
  /* font-weight: 600; */
}
  /* .cart-list-count-wrapper .cart-list-count{
    padding: 5px;font-weight: 600;
  } */

  header.header nav .add-to-cart-nav .Login-wrapper span{
    text-decoration: none;
    color: var(--navbar-text-light);
    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-weight: 600;
    display: inline-block;
    padding: 5px ;
    position: relative;
    cursor: pointer;
  }
  header.header nav .add-to-cart-nav .Login-wrapper span:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 6px;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    position: absolute;
    background: var(--nav-active-hover);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  header.header nav .add-to-cart-nav .Login-wrapper span:hover:after { 
    width: 100%; 
    left: 0; 
  }

@media screen and (max-width:768px){
  .add-to-cart-nav {
    display: none;
}
}

@media screen and (max-width:991px) {
  header.header nav ul.main-nav{display: none;}
  header.header nav .menu-icon{display: block;}

}


@media(max-width:575px){
  .header.header nav .menu-icon .hamburger-wrapper aside .hamburger-close-wrapper{width: 0%;}
  header.header nav .menu-icon .hamburger-wrapper aside ul{
      width: 100%;
  }
  /* header.header .logo-wrapper {
    width: 150px;
} */

}
@media (min-width:576px) and (max-width:767px){
  .header.header nav .menu-icon .hamburger-wrapper aside .hamburger-close-wrapper{width: 60%;}
  header.header nav .menu-icon .hamburger-wrapper aside ul{
      width: 40%;
  }
}

/* @media (min-width:768px) and (max-width:991px){
  .header.header nav .menu-icon .hamburger-wrapper aside .hamburger-close-wrapper{width: 70%;}
  header.header nav .menu-icon .hamburger-wrapper aside ul{
      width: 40%;
  }
} */
