.modal-custom{
    position: fixed;
    /* inset: 0; */
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    /* background-color: hsla(0,0%,46.7%,.4); */
    display: flex!important;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    transition: all 0.3s ease-in-out;
    /* overflow: hidden; */
    z-index: 99999;
    /* padding: 160px 20px 20px; */
    justify-content: center;
    /* background: rgba(255, 255, 255, 0.21); */
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    height: 100%;
    width: 100%;
    overflow-y: auto;
}
.modal-custom .modal-container{
    min-width: 500px;
    /* max-width: 800px; */
    max-width: 500px;
    /* min-height: 300px; */
    background-color: white;
    border-radius: 8px;
    /* padding-bottom: 10px; */
    position: relative;
    /* overflow: hidden; */
    /* max-height: 92vh; */
    /* overflow-y: auto; */
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4);
    margin-bottom: 62px;
    top: 50px;
}
.modal-custom .modal-container .close-modal-wrapper{
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    z-index: 99;
    font-size: 20px;
}
.modal-custom .modal-container .modal-body {
    max-height: 100%;
    /* overflow-y: scroll; */
}
.modal-custom .modal-container .modal-body::-webkit-scrollbar {
    width: 5px;
    transition: all 0.4s ease-in-out;
  }
  
  /* Track */
  .modal-custom .modal-container .modal-body::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Handle */
  .modal-custom .modal-container .modal-body::-webkit-scrollbar-thumb {
    background: #b9b5b5;
    border-radius: 5px;
  }
  
  /* Handle on hover */
  .modal-custom .modal-container .modal-body::-webkit-scrollbar-thumb:hover {
    background: #b0aeae;
  }




.modal-custom .modal-container .close {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.modal-custom .modal-container .modal-header {
    position: sticky;
    top: 0;
    padding: 10px 20px;
    background-color: #fff;
}
.modal-custom .modal-container .close:after,.modal-custom .modal-container .close:before {
    content: "";
    height: 20px;
    width: 20px;
    border-top: 1px solid #000;
    position: absolute;
    top: 20px;
    right: 4px;
    transform: rotate(-45deg);
}
.modal-custom .modal-container .close.zoom-close:after,.modal-custom .modal-container .close.zoom-close:before{
    border-top: 1px solid #fff;
}

.modal-custom .modal-container .close:before {
    right: 17px;
    transform: rotate(45deg);
}
.modal-custom .modal-container .close:hover{opacity: 0.3;}
.modal-custom .modal-container .closer {
    position: absolute;
    top: 10px;
    right: 17px;
    cursor: pointer;
}
.modal-custom .modal-container .closer  {
    /* color: #fff;  */
    text-shadow: 0 0 10px black;
    font-size: 22px;
}

.welcome-modal-wrapper{
    position: fixed;
    inset: 0;
    /* transform: translate(50%, 50%); */
    height: 100vh;
    /* top: 50%;
    left: 50%; */
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.welcome-modal-wrapper .welcome-modal-inner-wrapper {
    /* z-index: 9999;s */
    width: 400px;
    text-align: center;
    padding: 30px;
    background-color: #232323;
    /* background-color: #232323e6; */
    color: #fff;
    border-radius: 3px;
    animation: 1s fadeInUp;
    user-select: none;
    transition: all 0.2s ease-in-out;
}
.welcome-modal-wrapper .welcome-modal-inner-wrapper h2{
    margin: 0;
    font-weight: 500;
    font-size: 25px;
}


@media  (max-width:575px) {
    .welcome-modal-wrapper .welcome-modal-inner-wrapper {
        width: 90%;
    }
}
@media  (max-width:768px) {
    .modal-custom .modal-container{
        width: 90%;
        margin: 0 auto;
        max-width: 90%;
        min-width: 90%;
        max-height: 90vh;
    }
    .modal-custom .modal-container{
        max-width: 85%;
    }
}
/* @media (max-width: 991px){
    .modal-custom .modal-container{
        max-width: 85%;
    }
} */

@keyframes fadeInUp {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }