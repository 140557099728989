footer{
    background-color: var(--footer-bg);
    color: var(--footer-text-light);
    padding: 30px 2.5rem;
}

footer .footer-wrapper .footer-inner-wrapper{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

footer .footer-wrapper .footer-inner-wrapper .logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
footer .footer-wrapper .footer-inner-wrapper .logo-wrapper .logo-inner-wrapper{
    /* padding: 15px 0; */
    text-align: center;
    /* height: 5rem; */
    width: 250px;
}
footer .footer-wrapper .footer-inner-wrapper .logo-wrapper .logo-inner-wrapper img{
    max-height: 100%;
    height: auto;
    width: auto;
    max-width: 100%;
}
footer .footer-wrapper .footer-inner-wrapper .logo-wrapper .scheduler-wrapper .form-inner {
    display: flex;
    flex-direction: column;
    width: 65%;
    gap: 20px;
}
footer .footer-wrapper .footer-inner-wrapper .logo-wrapper .scheduler-wrapper .form-inner input{
    padding: 13px 5px;
    border: 0;
    border-bottom: 1px solid #fff;
    outline: none;
    transition: all 0.3s ease-in-out;
    width: 100%;
    /* border-radius: 3px; */
    background-color: transparent;
    color: #eee5e5;
}
footer .footer-wrapper .footer-inner-wrapper .logo-wrapper .scheduler-wrapper .form-inner input::placeholder{
    color: #9E9E9E;
    text-transform: capitalize;
}

footer .footer-wrapper .footer-inner-wrapper .logo-wrapper .scheduler-wrapper .form-inner button{
    padding: 12px 20px;
    font-size: 14px;
    line-height: 18px;
    background-color: #F3F3F3;
    color: #3E3E3E;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: 0px solid transparent;
    border-radius: 0;
    box-shadow: none;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: none;
    font-weight: 600;
    cursor: pointer;
    transition: color .25s ease, background-color .25s ease, border-color .25s ease, box-shadow .25s ease, opacity .25s ease;
    width: 100%;
}

footer .footer-wrapper .footer-inner-wrapper .logo-wrapper .scheduler-wrapper .form-inner button:hover{
    color: #3E3E3E;
    box-shadow: inset 0 0 200px rgba(0,0,0,0.1);
    text-decoration: none;
}



footer .footer-wrapper .footer-inner-wrapper .pages-contaienr ul{
    list-style-type: none;
    padding: 0;
    margin: 20px 0 0 0 ;
}
footer .footer-wrapper .footer-inner-wrapper .pages-contaienr ul li{
  text-align: center;
}
footer .footer-wrapper .footer-inner-wrapper .pages-contaienr ul li a{
    text-decoration: none;
    color: var(--navbar-text-light);
    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-weight: 600;
    display: inline-block;
    padding: 5px;
    /* margin: 10px 0; */
    margin: 0;
    position: relative;
    font-size: 20px;
}
footer .footer-wrapper .footer-inner-wrapper .pages-contaienr ul li a::after{
    background: none repeat scroll 0 0 transparent;
    bottom: 5px;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: var(--nav-active-hover);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

footer .footer-wrapper .footer-inner-wrapper .pages-contaienr ul li  a:hover:after {
    width: 100%;
    left: 0;
}

footer .footer-wrapper .footer-inner-wrapper .social-links {
    display: flex;
    height: 100%;
    align-items: center;
}

footer .footer-wrapper .footer-inner-wrapper .social-links .social-buttons {
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -10px;
  }
  footer .footer-wrapper .footer-inner-wrapper .social-links  .social-buttons__button {
    margin: 5px;
  }
  footer .footer-wrapper .footer-inner-wrapper .social-links  .social-button {
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    width: 45px;
    height: 45px;
    text-decoration: none;
  }
  footer .footer-wrapper .footer-inner-wrapper .social-links  .social-icons {
    width: 100%;
  }
  footer .footer-wrapper .footer-inner-wrapper .social-links  .social-button__inner {
    font-size: 2.3rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    /* background: #fff; */
    text-align: center;
  }
  footer .footer-wrapper .footer-inner-wrapper .social-links  .social-button i,
  footer .footer-wrapper .footer-inner-wrapper .social-links .social-button svg {
    position: relative;
    z-index: 1;
    transition: 0.3s;
    color: #fff;
  }
  footer .footer-wrapper .footer-inner-wrapper .social-links .social-button i {
    font-size: 20px;
  }
  footer .footer-wrapper .footer-inner-wrapper .social-links .social-button svg {
    height: 40%;
    width: 40%;
  }
  footer .footer-wrapper .footer-inner-wrapper .social-links .social-button::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    border-radius: 100%;
    transition: 0.3s;
  }
  footer .footer-wrapper .footer-inner-wrapper .social-links .social-button:focus,
  footer .footer-wrapper .footer-inner-wrapper .social-links  .social-button:hover {
    color: #fff;
    text-decoration: none;
  }
  footer .footer-wrapper .footer-inner-wrapper .social-links .social-button:focus::after,
  footer .footer-wrapper .footer-inner-wrapper .social-links .social-button:hover::after {
    width: 100%;
    height: 100%;
    margin-left: -50%;
  }
  footer .footer-wrapper .footer-inner-wrapper .social-links .social-button--linkedin::after {
    background: #0077b5;
  }
  footer .footer-wrapper .footer-inner-wrapper .social-links .social-button--twitter::after {
    background:#00ACEE;
  }

  @media (max-width:575px) {
    footer {
      padding: 30px 2rem!important;
    }
    footer .footer-wrapper .footer-inner-wrapper .logo-wrapper .logo-inner-wrapper{
      width: 100%;
    }
  }

  @media (max-width:768px){
    footer .footer-wrapper .footer-inner-wrapper .logo-wrapper .scheduler-wrapper .form-inner{width: 100%;}
  }

  @media (max-width: 991px){
    footer .footer-wrapper .footer-inner-wrapper {
        flex-direction: column;
        align-items: center;
    }
    footer .footer-wrapper .footer-inner-wrapper :is(.logo-wrapper .logo-inner-wrapper,.pages-contaienr ul){text-align: center;}
    /* footer .footer-wrapper .footer-inner-wrapper .pages-contaienr ul */
    footer .footer-wrapper .footer-inner-wrapper .logo-wrapper .scheduler-wrapper form{
        display: flex;
        justify-content: center;
    }
    footer .footer-wrapper .footer-inner-wrapper .social-links{
        justify-content: center;
    }
    footer .footer-wrapper .footer-inner-wrapper .logo-wrapper .scheduler-wrapper .form-inner{align-items: center;}
  }