.checkbox-wrapper-custom {
    position: relative;
    height: 21px;
  }
  
  .checkbox-wrapper-custom input[type="radio"] {
    display: none;
    visibility: hidden;
  }
  .checkbox-wrapper-custom .cbx {
    -webkit-perspective: 20;
    perspective: 20;
    display: inline-block;
    border: 2px solid #b1b1b1;
    background: #e8e8eb;
    border-radius: 4px;
    transform: translate3d(0, 0, 0);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .checkbox-wrapper-custom .cbx:hover {
    border-color: #00B5FF;
  }
  .checkbox-wrapper-custom .flip {
    display: block;
    transition: all 0.4s ease;
    transform-style: preserve-3d;
    position: relative;
    width: 15px;
    height: 15px;
  }
  .checkbox-wrapper-custom input[type="radio"]:checked + .cbx {
    border-color: #00B5FF;
  }
  .checkbox-wrapper-custom input[type="radio"]:checked + .cbx .flip {
    transform: rotateY(180deg);
  }
  .checkbox-wrapper-custom .front,
  .checkbox-wrapper-custom .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    border-radius: 2px;
  }
  .checkbox-wrapper-custom .front {
    background: #fff;
    z-index: 1;
  }
  .checkbox-wrapper-custom .back {
    transform: rotateY(180deg);
    background: #00B5FF;
    text-align: center;
    color: #fff;
    line-height: 15px;
    box-shadow: 0 0 0 1px #00B5FF;
  }
  .checkbox-wrapper-custom .back svg {
    margin-top: -1px;
    fill: none;
  }
  .checkbox-wrapper-custom .back svg path {
    stroke: #fff;
    stroke-width: 2.5;
    stroke-linecap: round;
    stroke-linejoin: round;
  }


  /* custom cbx sm */


  .checkbox-wrapper-custom-sm {
    position: relative;
    height: 14px;
  }
  
  .checkbox-wrapper-custom-sm input[type="radio"] {
    display: none;
    visibility: hidden;
  }
  .checkbox-wrapper-custom-sm .cbx {
    -webkit-perspective: 18;
    perspective: 18;
    display: inline-block;
    border: 2px solid #b1b1b1;
    background: #e8e8eb;
    border-radius: 4px;
    transform: translate3d(0, 0, 0);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .checkbox-wrapper-custom-sm .cbx:hover {
    border-color: #00B5FF;
  }
  .checkbox-wrapper-custom-sm .flip {
    display: block;
    transition: all 0.4s ease;
    transform-style: preserve-3d;
    position: relative;
    width: 10px;
    height: 10px;
  }
  .checkbox-wrapper-custom-sm input[type="radio"]:checked + .cbx {
    border-color: #00B5FF;
  }
  .checkbox-wrapper-custom-sm input[type="radio"]:checked + .cbx .flip {
    transform: rotateY(180deg);
  }
  .checkbox-wrapper-custom-sm .front,
  .checkbox-wrapper-custom-sm .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 2px;
  }
  .checkbox-wrapper-custom-sm .front {
    background: #fff;
    z-index: 1;
  }
  .checkbox-wrapper-custom-sm .back {
    transform: rotateY(180deg);
    background: #00B5FF;
    text-align: center;
    color: #fff;
    line-height: 10px;
    box-shadow: 0 0 0 1px #00B5FF;
  }
  .checkbox-wrapper-custom-sm .back svg {
    margin-top: -1px;
    fill: none;
  }
  .checkbox-wrapper-custom-sm .back svg path {
    stroke: #fff;
    stroke-width: 2.5;
    stroke-linecap: round;
    stroke-linejoin: round;
  }