.notfound-wrapper {
    position: relative;
    height: 90vh
}

.notfound-wrapper .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    user-select: none;
}

.notfound {
    max-width:86%;
    width: 100%;
    line-height: 1.4;
    text-align: center
}

.notfound .notfound-404 {
    position: relative;
    height: 200px;
    margin: 0 auto 20px;
    z-index: -1
}

.notfound .notfound-404 h1 {
    font-family: montserrat, sans-serif;
    font-size: 190px;
    font-weight: 200;
    margin: 0;
    color: var(--text-dark);
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.notfound .notfound-404 h2 {
    font-family: montserrat, sans-serif;
    font-size: 28px;
    font-weight: 400;
    text-transform: capitalize;
    color: var(--text-dark);
    /* background: #fff; */
    padding: 10px 5px;
    margin: auto;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.notfound a {
    font-family: montserrat, sans-serif;
    display: inline-block;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    padding: 13px 23px;
    background: var(--button-bg);
    font-size: 18px;
    -webkit-transition: .2s all;
    transition: .2s all
}

.notfound a:hover {
    color: var(--text-theme);
    background: var(--btn-dark-bg);
}

@media only screen and (max-width:767px) {
    .notfound .notfound-404 h1 {
        font-size: 148px
    }
}

@media only screen and (max-width:480px) {
    .notfound .notfound-404 {
        height: 148px;
        margin: 0 auto 10px
    }

    .notfound .notfound-404 h1 {
        font-size: 86px
    }

    .notfound .notfound-404 h2 {
        font-size: 16px
    }

    .notfound a {
        padding: 7px 15px;
        font-size: 14px
    }
}