:root {
  --body-bg: #EEE;
  --theme-bg: #00B5FF;
  --navbar-bg: #1E1F20;
  --navbar-text-light: #fff;
  --nav-active-hover: #00B5FF;
  --cart-list-bg: #fff;
  --cart-list-text: #000;
  --text-light: #fff;
  --text-dark: #1E1F20;
  --text-theme: #00B5FF;
  --text-theme-hover: #00B5FF;
  --button-bg: #00B5FF;
  --icon-light: #fff;
  --footer-bg: #1E1F20;
  --footer-text-light: #fff;
  --banner-title-font-family: "Poppins", Arial, Helvetica, sans-serif;
  --text-danger: #F32013;
  --scroll-top-bg: #1E1F20;
  --cart-list-canvas-bg: #1E1F20;
  --checkout-button-bg: #1E1F20;
  --card-button-bg: #1E1F20;
  --add-to-cart: #1E1F20;
  --submit-button-bg: #1E1F20;
  --social-links-bg: #1E1F20;
  --para-text-color:#272727;
  --balls-loader: #acacac;
  --intreset-btn: #1E1F20;
  --open-gallery-btn-bg: #00B5FF;
  --open-gallery-btn-border: #00B5FF;
  --btn-dark-bg: #1E1F20;
  --intreseted-icon-text: #00B5FF;
  --like-icon-text: #ff0000;
  --img-slider-bg: #fff;
  --anchor-theme: #00B5FF;
  --selection-bg: #00B5FF;
  --selection-text: #fff;
  --mobile-quick-view: #00B5FF;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
::selection {
  color: var(--selection-text);
  background: var(--selection-bg);
}
body {
  background-color: var(--body-bg) !important;
  font-family: Arial, Helvetica, sans-serif!important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important; */
}

body::-webkit-scrollbar {
  width: 10px;
  transition: all 0.4s ease-in-out;
}

/* Track */
body::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #b9b5b5;
  border-radius: 5px;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #b0aeae;
}
.Toastify__toast-container {
  z-index: 999999!important;
}

.App {
  min-height: 100vh;
}
.App.home{
  padding-top: 55px;
}

.cursor-disable{
  cursor: not-allowed!important;
  user-select: none;
  /* pointer-events: none; */
}

/* BUTTON START */
.btn-custom {
  position: relative;
  overflow: hidden;
}

.btn-custom .label {
  position: relative;
  top: -1px;
}

.btn-custom .btn-transition {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 800ms;
  background-color: var(--button-bg);
  /* border-radius: 9999px; */
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.btn-custom:hover .btn-transition {
  width: 100%;
  height: 100%;
}

/* BUTTON END */
/* HTML: <div class="loader"></div> */
.balls-loader-wrapper{
  height: 20vh;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
}
.balls-loader-inner-wrapper {
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
}
.balls-loader-wrapper .balls-loader {
  width: 60px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side,var(--balls-loader) 90%,#0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}

.btn-login{
  height: 38px;
  width: 76px;
}

.form-control{
  border-radius: 4px!important;
  min-height: 38px;
    outline: 0!important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms!important;
    background-color: hsl(0, 0%, 100%)!important;
    border-color: hsl(0, 0%, 80%)!important;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
}
.form-control:focus{
  box-shadow: 0 0 0 0.15rem rgba(13,110,253,.15)!important;
}
.form-label{
  font-weight: 600!important;
  margin-bottom: 0.2rem!important;
  color: #2f2e2e;
}


/* SCROLL TO TOP START */
.scroll-wrapper {
  /* display: none; */
  /* transform: translateX(200px); */
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 100;
  font-size: 18px;
  border: none;
  /* height: 50px; */
  /* width: 50px; */
  outline: none;
  /* background-color: #ddd; */
  /* color: white; */
  cursor: pointer;
  /* padding: 15px; */
  /* border-radius: 50%; */
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2); */
  transition: all 0.4s ease-in-out;
  filter: drop-shadow(rgb(255, 255, 255) 0px 0px 30px);
}
.scroll-wrapper .scroll-inner-wrapper{
  transform: translateY(200px);
}
.scroll-wrapper .scroll-inner-wrapper:hover {
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}

.scroll-wrapper .scroll-inner-wrapper i {
  color: #fff;
}

.scroll-wrapper .scroll-inner-wrapper:hover i{
  color: var(--anchor-theme);
}
.scroll-wrapper .scroll-inner-wrapper.active {
  /* display: block; */
  transform: translateX(0);
}

.scroll-wrapper .scroll-inner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: var(--scroll-top-bg);
  /* color: white; */
  cursor: pointer;
  padding: 15px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.25s ease-in-out;
  height: 45px;
  width: 45px;
}
.scroll-wrapper .scroll-inner-wrapper.shareable-wrapper{
  transform: translate(0);
  margin: 10px 0;
}

/* SCROLL TO TOP END */

/* REP COVER  START */
.rep-cover-wrapper{
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 300px;
}
.rep-cover-wrapper .overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
}
.rep-cover-wrapper .rep-cover-inner-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  z-index: 9;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.rep-cover-wrapper .rep-cover-inner-wrapper .rep-profile-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.rep-cover-wrapper .rep-cover-inner-wrapper .rep-profile-container .rep-info-wrapper {
  display: flex;
  align-items: center;
}
.rep-cover-wrapper .rep-cover-inner-wrapper .rep-profile-container .rep-info-wrapper .avatar-container{
  width: 110px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
}
.rep-cover-wrapper .rep-cover-inner-wrapper .rep-profile-container .rep-info-wrapper .avatar-container img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.rep-cover-wrapper .rep-cover-inner-wrapper .rep-profile-container .rep-info-wrapper .name-info-wrapper{
  color: #fff;
  padding-left: 20px;
}
.rep-cover-wrapper .rep-cover-inner-wrapper .rep-profile-container .rep-info-wrapper .name-info-wrapper .rep-title{
  font-size: 28px;
}
/* .rep-cover-wrapper .rep-cover-inner-wrapper .rep-profile-container .rep-info-wrapper .name-info-wrapper p{

} */
.rep-cover-wrapper .rep-cover-inner-wrapper .rep-profile-container .rep-contact-wrapper{
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.rep-cover-wrapper .rep-cover-inner-wrapper .rep-profile-container .rep-contact-wrapper .btn-cover-contact{
  background-color: #f0f0f0;
  text-transform: capitalize;
  color: #3c3c3c;
  padding: 7px 10px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 3px;
  min-width: 135px;
  text-align: center;
  border: none;
}
.rep-cover-wrapper .rep-cover-inner-wrapper .rep-profile-container .rep-contact-wrapper .btn-cover-contact:hover{
  color: var(--anchor-theme);
  transition: all .2s ease-in-out;
}
.qr-wrapper .qr-inner-wrapper {
  text-align: center;
  max-width: 80%;
  margin-inline: auto;
}
.qr-wrapper .qr-inner-wrapper img{
  width: 100%;
  height: auto;
  max-height: 100%;
}
/* REP COVER  END */

.load-more-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Banner START */
section.banner-wrapper .banner-inner-wrapper {
  position: relative;
}

section.banner-wrapper .banner-inner-wrapper .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .6);
}

section.banner-wrapper .banner-inner-wrapper .banner-inner-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--text-light);
  position: relative;
  z-index: 4;
}

section.banner-wrapper .banner-inner-wrapper .banner-inner-text h2.title {
  font-weight: 400;
  font-family: var(--banner-title-font-family);
}
/*============= BANNER END =================*/

/*========= LIKE BUTTON START ===============*/
.like-button-wrapper{
  padding: 5px 15px;
    background-color: var(--social-links-bg);
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
}
.like-button-wrapper
/*========= LIKE BUTTON END ===============*/

/* Banner HOME */

section.banner-wrapper.home-banner .banner-inner-wrapper {
  padding: 0px 2.5rem;
}

section.banner-wrapper.home-banner .banner-inner-wrapper .banner-inner-text {
  justify-content: center;
  /* width: 60%; */
  padding: 50px 0 10px;
}

section.banner-wrapper.home-banner .banner-inner-wrapper .banner-inner-text .banner-content-container {
  width: 60%;
}

section.banner-wrapper.home-banner .banner-inner-wrapper .banner-inner-text .banner-content-container h2 {
  font-size: 50px;
  margin-bottom: 40px;
}

section.banner-wrapper.home-banner .banner-inner-wrapper .banner-inner-text .banner-content-container .products-btn-container {
  margin: 5px 0;
  padding: 10px 0;
}

section.banner-wrapper.home-banner .banner-inner-wrapper .banner-inner-text .banner-content-container .products-btn-container a {
  text-decoration: none;
  margin: 5px 0;
  padding: 10px 20px;
  font-weight: 600;
  background-color: transparent;
  color: var(--text-light);
  border: 2px solid var(--button-bg);
  transition: all 0.2s ease-in-out;
}

section.banner-wrapper.home-banner .banner-inner-wrapper .banner-inner-text .banner-content-container .products-btn-container a:hover {
  background-color: var(--button-bg);
}



/* Banner END */




/* Product START */
.product-wrapper , .cart-wrapper, .about-us-wrapper{
  min-height: 100vh;
}

.product-wrapper .product-inner-wrapper {
  padding: 30px 0;
}
.product-wrapper .product-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 89;
  transition: all 0.4s ease-in-out;
}

.product-wrapper .product-header-wrapper {
  padding: 0 10px;
}

.product-wrapper .product-header-wrapper .product-header-inner-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid #ddd; */
  padding: 10px 0;
}

.product-wrapper .product-header-wrapper .product-header-inner-wrapper .product-view-type {
  display: flex;
  align-items: center;
  /* gap: 20px; */
  /* box-shadow: -1px 9px 17px 0 rgba(0, 0, 0, 0.2), 0px 1px 1px 0 rgba(255, 255, 255, 0.5); */
  box-shadow: 3px 4px 18px  rgba(0, 0, 0, 0.1), 0px 1px 1px 0 rgba(255, 255, 255, 0.3);
  border-radius: 5px;
}

.product-header-wrapper .product-header-inner-wrapper .product-filter-wrapper {
  width: 40%;
  display: flex;
  gap: 0.5rem;
}

.product-header-wrapper .product-header-inner-wrapper .product-filter-wrapper :is(.select-filter-wrapper, .search-filter-wrapper) {
  width: 50%;
}

.product-header-wrapper .product-header-inner-wrapper .product-filter-wrapper select {
  padding: 10px 7px !important;
}

.product-header-wrapper .product-header-inner-wrapper .product-filter-wrapper :is(input, select) {
  padding: 7px 10px;
  border: 1px solid #ddd;
  outline: none;
  transition: all 0.3s ease-in-out;
  width: 100%;
  border-radius: 3px;
}

.product-header-wrapper .product-header-inner-wrapper .product-filter-wrapper :is(input, select):focus-visible {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, .2);
  transition: all 0.1s ease-in-out;
}

.product-wrapper .product-header-wrapper .product-header-inner-wrapper .product-view-type .view {
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.1s ease-in-out;
  padding: 10px 20px;
  background-color: #ffffff63;
  font-size: 14px;
  user-select: none;
  outline: 0;
  border: 0;
}

.product-wrapper .product-header-wrapper .product-header-inner-wrapper .product-view-type .view:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.product-wrapper .product-header-wrapper .product-header-inner-wrapper .product-view-type .view:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.product-wrapper .product-header-wrapper .product-header-inner-wrapper .product-view-type .view span {
  font-weight: 600;
  transition: all 0.1s ease-in-out;
  text-transform: capitalize;
  padding-left: 10px;
}

.product-wrapper .product-header-wrapper .product-header-inner-wrapper .product-view-type .view i {
  font-size: 16px;
  transition: all 0.1s ease-in-out;
}

.product-wrapper .product-header-wrapper .product-header-inner-wrapper .product-view-type .view.active {
  background-color: var(--button-bg);
  color: var(--text-light);
  /* box-shadow: inset 6px 6px 10px 0 rgba(0, 0, 0, 0.2), inset -6px -6px 30px 0 rgba(255, 255, 255, 0.5); */
  box-shadow: inset 3px 2px 12px 0 rgba(0, 0, 0, 0.5), inset -6px -6px 30px 0 rgba(255, 255, 255, 0.5);
  opacity: 0.8;
}
/* .product-wrapper .product-header-wrapper .product-header-inner-wrapper .product-view-type .view:not(:first-child,:last-child){
 border-left: 1px solid #00000040;
 border-right: 1px solid #00000040;
} */

.product-wrapper .product-header-wrapper .product-header-inner-wrapper .product-view-type .view.active:hover {
  color: var(--text-light);
}

.product-wrapper .product-header-wrapper .product-header-inner-wrapper .product-view-type .view:hover {
  color: var(--nav-active-hover);
}
.product-wrapper .product-header-wrapper .product-header-inner-wrapper .product-view-type .view.cursor-disable:hover{
  color: light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3));
}
.product-wrapper .products-body-wrapper {
  position: relative;
}

.product-wrapper .products-body-wrapper .rep-capture-email-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: rgba(0, 0, 0, .3);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border-radius: 10px;
  z-index: 99;
  /* background-image: url('./assets/images/blur-bg.webp'); */
}
.product-wrapper .products-body-wrapper .rep-capture-email-wrapper .rep-capture-email-inner-wrapper{
  left: 0;
  position: sticky;
  top: 15%;
  transform: translate(-0%, 10%);
  width: -webkit-max-content;
  /* max-width: max-content; */
  width: 100%;
  display: flex;
  justify-content: center;
}
.product-wrapper .products-body-wrapper .rep-capture-email-wrapper .rep-capture-email-inner-wrapper .supplier-email-wrapper{
  width: fit-content;
  background-color: #fff;
  border-radius: 10px;
}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper {
  padding: 0 0 35px;
  display: flex;
  flex-wrap: wrap;
  transition: all 0.3s ease-in-out;
}

/* PRODUCT CARD GRID VIEW (view 1 card) START*/

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper {
  transition: all 0.2s ease-in-out;
}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper {
  /* width: 270px; */
  /* box-shadow: 0 0 5px 0 rgba(0,0,0,.15); */
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  /* border: 1px solid #f5f5f5; */
  overflow: hidden;
  transition: all 0.2s ease-in-out;
margin: 25px 10px;
  position: relative;
}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper:is(:hover,.show-backdrop) {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
  /* box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12); */
  z-index: 4;
  position: relative;
  overflow: unset;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  z-index: 100;
}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper.show-backdrop {
 z-index: 101;
}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-header {
  position: relative;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-header .product-img-slider-wrapper .product-img-slider-inner-wrapper .manfacture-logo img{
  height: 26px;
  width: 75px;
  object-fit: cover;
}
.product-img-slider-wrapper {
  height: 300px;
}

.product-img-slider-wrapper .product-img-slider-inner-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--img-slider-bg);
  /* border-radius: 4px;rgba(0, 0, 0, 0.6) */
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
.product-img-slider-wrapper .product-img-slider-inner-wrapper .manfacture-logo{
  position: absolute; bottom: 1px; left: 0; background-color: #fff; padding: 4px;user-select: none;
}
.product-img-slider-wrapper .product-img-slider-inner-wrapper .manfacture-logo img{
  height: 26px!important;
  width: 105px!important;
  object-fit: contain!important;
  border: 0!important;
  filter: drop-shadow(2px 3px 6px rgba(0,0,0,0.4));
  user-select: none;
}
.product-img-slider-wrapper .product-img-slider-inner-wrapper .img-count-container.show {
  display: block;
}

.product-img-slider-wrapper .product-img-slider-inner-wrapper .img-count-container {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  display: none;
}

.product-img-slider-wrapper .product-img-slider-inner-wrapper .img-count-container span {
  background-color: #2d2b2b;
  color: #fff;
  border-radius: 20px;
  padding: 2px 10px;
  font-size: 12px;
  font-weight: 600;
}

.product-img-slider-wrapper .product-img-slider-inner-wrapper :is(i,svg) {
  /* color: #fff; */
  filter: drop-shadow(0px 2px 7px rgba(0,0,0,0.3));
  text-shadow: 0 0 10px black;
  cursor: pointer;
  display: none;
  transition: all 0.3s ease-in-out;
  padding: 2px;
  font-size: 24px;
  z-index: 99;
  box-sizing: border-box;
  height: 1.6em;
  width: 1.6rem;
  background-color: #fff;
  border-color: #e6e6e6;
  opacity: 1;
  overflow: visible;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 1px 2px 0px rgba(0, 0, 0, 0.14), 2px 1px 5px 0px rgba(0, 0, 0, 0.12);
  color: #212529bd !important;
  border-radius: 0.375rem;
  font-size: 1rem !important;
}
.product-img-slider-wrapper .product-img-slider-inner-wrapper img.lazyload-img{
  width: 50px !important;
  height: 50px !important;
  transform: translate(0px, 223%)!important;
}
.slider-text-wrapper{
  padding: 25px;
  background-color: rgba(0, 0, 0, 0.9);/*#616161 .. #7A7A7A*/
  color: #fff;
  height: 100%;
  width: 100%;
  text-align: left;
  font-size: 14px;
  user-select: none;
  -webkit-user-select: none;
}

.product-img-slider-wrapper .product-img-slider-inner-wrapper .gallery-popup-btn svg {
  background-color: transparent !important;
  /* border: 1px solid var(--open-gallery-btn-border) !important; */
  border-radius: 4px !important;
  bottom: 0;
  box-shadow: unset !important;
  color: #fff !important;
  padding: 4px;
  font-size: 14px !important;
}
.product-card-container .product-card-header .product-img-slider-wrapper .overlay{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: url('./assets/icon/zoom-in-27-bg.svg'), auto;
  /* cursor: -moz-zoom-in; 
  cursor: -webkit-zoom-in;
  cursor: zoom-in; */

}
:is(.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-header, .quick-view-wrapper) .product-img-slider-wrapper:hover .product-img-slider-inner-wrapper :is(i,svg) {
  display: block;
  transition: all 0.3s ease-in-out;
}

.product-img-slider-wrapper .product-img-slider-inner-wrapper .btnPrev {
  position: absolute;
  top: 50%;
  left: 5%;
}

.product-img-slider-wrapper .product-img-slider-inner-wrapper .btnNext {
  position: absolute;
  top: 50%;
  right: 5%;
}

.product-img-slider-wrapper .product-img-slider-inner-wrapper img {
  width: 100%;
  height: 100%;
  transition: all 0.4s ease-in-out;
  object-fit: cover;
}

/* .product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper:hover .product-card-header .tags-container {display: flex;transition: all 0.2s ease-in-out;} */
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-header .tags-container {
  position: absolute;
  top: 10px;
  left: 0;
  display: flex;
  transition: all 0.2s ease-in-out;
  padding: 7px 14px;
  width: fit-content;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-header .tags-container .fav-container {
  height: fit-content;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-header .tags-container .fav-container i {
  padding: 5px;
}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-header .tags-container .tags-inner-container .tag {
  border-radius: 20px;
  color: var(--text-light);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  width: max-content;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-header .tags-container .tags-inner-container .tag.type {
  background-color: #7556F2;
}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-header .tags-container .tags-inner-container .tag.product-from {
  background-color: #C82021;
}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-header .tags-container .tags-inner-container .tag span {
  padding: 2px 5px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-body {
padding: 15px 15px 0;
  /* z-index: 5; */
  position: relative;
  background-color: #fff;
min-height: 90px;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-body.active-feedback{
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-body .product-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-body .product-title-container .product-name {
  font-size: 20px;
  line-height: 1.6;
  /* border-bottom: solid 2px #00000008; */
  margin-bottom: 0;
  /* display: inline-block; */
  /* cursor: pointer; */
  position: relative;
  /* padding-left: 10px; */
  overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-body .product-title{
  font-size: 13px;
  font-style: italic;
  color: #8c8c8c;
  margin: 2px 0 0 0;
  height: 19.5px;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-body .readmore{
  padding: 0 2px;
  color: var(--anchor-theme);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: 13px;
  font-style: italic;
  background-color: #fff;
  display: inline-block;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-body  .readmore:hover{
  text-decoration: underline;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-body .active{
  height: unset;
  display: block;
  /* overflow: unset; */
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container:hover .product-card-body .active{
  height: unset;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-body .product-title-container .product-title label{cursor: pointer;}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-body .product-title-container .product-title::after {
  background: none repeat scroll 0 0 transparent;
  bottom: -1px;
  content: "";
  display: block;
  height: 2px;
  left: 0%;
  position: absolute;
  background: var(--nav-active-hover);
  transition: width 0.2s ease 0s, left 0.2s ease 0s;
  width: 0;
}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-body .product-title-container .product-title:hover::after {
  width: 100%;
  left: 0;
}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-body .price-container .price-title {
  font-size: 18px;
  margin-bottom: 5px;
  font-family: cursive;
  font-weight: bold;
  color: #535a5e;
  pointer-events: none;
  user-select: none;
}
.description *{background-color: transparent!important;}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container :is(.product-card-body , .product-card-footer) .description-container {
  font-size: 13px;
  padding: 10px 0;
}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-body .description-container p {
  margin-bottom: 0;
  width: 100%;
  line-height: 1.3em;
  height: 2.6em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: #777;
}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer {
  /* display: none; */
  /* padding: 10px; */
  transition: all 0.2s ease-in-out;
  /* position: absolute;
  top: calc(100%);
  z-index: -424; */
  width: 100%;
  background-color: #fff;
  /* transform: translateY(-46px); */
  opacity: 0;

}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer .description-container{
  padding: 10px 15px;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer .description-container p {
  margin: 0;
  font-size: 13px!important;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer .description-container p::first-letter{
  text-transform: capitalize;
}
.description p::first-letter{
  text-transform: capitalize;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer .description-container p::first-letter {
  text-transform: capitalize;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container {
  position: relative;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper.show-backdrop .product-card-container{
  z-index: 99;
  filter: drop-shadow(rgb(255, 255, 255) 0px 0px 11px);
}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper :is(.product-card-inner-wrapper,.product-card-inner-wrapper.show-backdrop) :is(.product-card-container:hover,.product-card-container) .product-card-footer {
  /* display: block; */
  transition: all 0.2s ease-in-out;
  transform: translateY(0);
  opacity: 1;
  /* box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2); */
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .share-message{
  font-size: 13px;
  font-style: italic;
  color: #8c8c8c;
  padding-left: 15px;
}
/* .product-card-inner-wrapper.show-backdrop
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper :is(.product-card-inner-wrapper,.product-card-inner-wrapper.show-backdrop) :is(.product-card-container:hover,.product-card-container) .product-card-footer */

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer .product-card-inner-footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* gap: 1px; */
  flex-wrap: wrap;
}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer .product-card-inner-footer .action {
  width: 50%;
  text-align: center;
  /* border: 1px solid #000; */
padding: 10px 0;
  /* background-color: var(--button-bg); */
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #fff;
font-size: 13px;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer .product-card-inner-footer .action.submitted-wrapper:hover{
  background-color: #fff!important;
  border: unset!important;
}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer .product-card-inner-footer .action {
  border-right: 1px solid #eee;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #eee;
  height: 45px;
}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer .product-card-inner-footer .action:hover {
  background-color: var(--card-button-bg);
  border: 1px solid #000;
  border-right: 1px solid #000!important;
  border-left: 1px solid #000!important;
  border-bottom: 1px solid #000!important;
  color: #fff;
}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer .product-card-inner-footer .action:nth-child(2) {
  border-right: 1px solid #fff;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .actions-wrapper{
  display: flex;
  align-items: center;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .actions-wrapper .action {
  padding: 5px 3px;
  margin-left: 15px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #535353;
  font-size: 18px;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .action.quick-review:is(:hover,.active) {
  /* background-color: var(--button-bg); */
  /* border: 1px solid var(--button-bg); */
  color: var(--mobile-quick-view);
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .action.heart-action:is(:hover,.active) {
  color:var(--like-icon-text);
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer .product-card-inner-footer .action.feed-submitted-message{
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* gap: 10px; */
  cursor: default;
  padding: 15px;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer .product-card-inner-footer .action.feed-submitted-message .form-status-title{
  margin-bottom: 0;
font-size: 1.2rem;
  margin-right: 10px;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer .product-card-inner-footer .action.feed-submitted-message:hover{
  background-color: #fff!important;
  border: 1px solid #eee!important;
  color: #000;
}
/* .product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer .product-card-inner-footer .action.feed-submitted-message:hover img{
  filter: drop-shadow(2px 0px 0px #fff);
} */
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer .product-card-inner-footer .action:hover {
  /* box-shadow: 0 0 5px 0 rgba(0,0,0,.4); */
  z-index: 4;
  /* background-color: #70a4ba; */
  /* background-color: var(--button-bg); */
  transition: all 0.3s ease-in-out;
}

.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer .product-card-inner-footer .action:is(:hover, .active) i {
  color: #fff;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer .product-card-inner-footer .action span {
  font-weight: 600;
  text-transform: capitalize;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer .product-card-inner-footer .action i {
  /* color: #fff; */
  /* padding: 10px; */
  transition: all 0.3s ease-in-out;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer .product-card-inner-footer .action svg{
  padding: 0 5px;
  font-size: 18px;
}

/* .product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer .product-card-inner-footer .action:hover i {
  transform: scale(1.1);
} */

/* =================PRODUCT CARD GRID VIEW (view 1 card) END================ */

/* =================PRODUCT CARD LIST VIEW (view 2 card) START============== */

.product-card-list-wrapper {
  margin: 15px;
  background-color: #fff;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
}

.product-card-list-wrapper:hover {
  border-radius: 0;
}

.product-card-list-wrapper .product-card-list-inner-wrapper {
  width: 100%;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); */
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.09);
  transition: all 0.2s ease-in-out;
  /* display: flex;
  flex-wrap: wrap; */
  border-radius: 20px;
  /* overflow: hidden; */
  /* border: solid 2px #e4e1e1; */
  /* align-items: center; */
  /* justify-content: center; */
  padding: 20px;
}

.product-card-list-wrapper .product-card-list-inner-wrapper:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
  border-radius: 0;
  border: unset;
}

.product-card-list-wrapper .product-card-list-inner-wrapper .product-img-slider-wrapper {
  width: 45%;
  height: auto;
  max-height: 600px;
  max-width: 472px;
  position: relative;
}
.product-card-list-wrapper .product-card-list-inner-wrapper .detail-landing-images-wrapper{
  width: 100%;
  height: 350px;
  display: flex;
  align-items: center;
  /* gap: 10px; */
  padding: 15px 0;
  position: relative;
}
.product-card-list-wrapper .product-card-list-inner-wrapper .detail-landing-image-contianer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
}
.product-card-list-wrapper .product-card-list-inner-wrapper .detail-landing-image-contianer{
  position: relative;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
}
.product-card-list-wrapper .product-card-list-inner-wrapper .detail-landing-image-contianer .overlay{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .1);
  transition: all 0.3s ease-in-out;
}
.product-card-list-wrapper .product-card-list-inner-wrapper .detail-landing-image-contianer:hover .overlay{
  /* display: block; */
  transition: all 0.3s ease-in-out;
  background-color: rgba(0, 0, 0, .5);

}
.product-card-list-wrapper .product-card-list-inner-wrapper .detail-landing-image-contianer{height: 100%;}
.product-card-list-wrapper .product-card-list-inner-wrapper .detail-landing-image-contianer.detail-image-display-one{width: 50%;}
.product-card-list-wrapper .product-card-list-inner-wrapper .detail-landing-image-contianer:is(.detail-image-display-two,.detail-image-display-three){width: 25%;}
.product-card-list-wrapper .product-card-list-inner-wrapper .detail-landing-image-contianer.detail-image-display-two{margin: 0 10px;}
.product-card-list-wrapper .product-card-list-inner-wrapper .detail-landing-image-contianer:hover .gallery-popup-btn{display: flex;} 
.product-card-list-wrapper .product-card-list-inner-wrapper .product-img-slider-wrapper .product-img-slider-inner-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: unset;
}
.product-card-list-wrapper .product-card-list-inner-wrapper .detail-landing-image-contianer:hover img {
  transform: scale(1.2);
  transition: all 0.5s ease-in-out;
}
.product-card-list-wrapper .product-card-list-inner-wrapper .product-img-slider-wrapper .product-img-slider-inner-wrapper img {
  width: 100%;
  height: 100%;
  
}

.product-card-list-wrapper .product-card-list-inner-wrapper .product-img-slider-wrapper .product-img-slider-inner-wrapper .img-count-container {
  position: absolute;
  top: 10px;
  left: 12px;
  display: none;
}

.product-card-list-wrapper .product-card-list-inner-wrapper .product-img-slider-wrapper .product-img-slider-inner-wrapper .img-count-container.show {
  display: block;
}

.product-card-list-wrapper .product-card-list-inner-wrapper .product-img-slider-wrapper .product-img-slider-inner-wrapper .img-count-container span {
  background-color: #2d2b2b;
  color: #fff;
  border-radius: 20px;
  padding: 2px 10px;
  font-size: 10px;
  font-weight: 600;
}

.product-card-list-wrapper .product-card-list-inner-wrapper .product-img-slider-wrapper .product-img-slider-inner-wrapper i {
  color: #fff;
  /* filter: drop-shadow(0px 2px 7px black); */
  text-shadow: 0 0 10px black;
  cursor: pointer;
  display: none;
  transition: all 0.3s ease-in-out;
  padding: 2px;
  z-index: 77;
  /* transform: translate(0, -50%); */
}

.product-card-list-wrapper .product-card-list-inner-wrapper .product-img-slider-wrapper:hover .product-img-slider-inner-wrapper i {
  display: block;
}

.product-card-list-wrapper .product-card-list-inner-wrapper .product-img-slider-wrapper .product-img-slider-inner-wrapper .btnPrev {
  position: absolute;
  top: 50%;
  left: 3%;
}

.product-card-list-wrapper .product-card-list-inner-wrapper .product-img-slider-wrapper .product-img-slider-inner-wrapper .btnNext {
  position: absolute;
  top: 50%;
  right: 3%;
}
.product-card-list-wrapper .product-card-list-inner-wrapper .product-img-slider-wrapper .gallery-modal-opener{
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 66;
    /* background-color: rgba(0,0,0,0.5); */
    /* transform: translate(13%, 0%); */
    cursor: pointer;
}
.product-card-list-wrapper .product-card-list-inner-wrapper .product-img-slider-wrapper .product-img-slider-inner-wrapper .zoom-in-btn-container {
  position: absolute;
  top: 17px;
  right: 16px;
}
.product-card-list-wrapper .product-card-list-inner-wrapper .product-img-slider-wrapper .product-img-slider-inner-wrapper .zoom-in-btn-container:hover i{
  transform: scale(1.3);
} 

.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body {
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .product-title{
  color: var(--para-text-color);
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 0;
}
.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .description{
  padding: 10px 0;
}

.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .description a{
  color: var(--text-theme-hover);
}
/* .product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .price-container h2{
  font-size: 20px;
} */
.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .price-action-wrapper {
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .price-action-wrapper .comment-wrapper {
  /* background-color: var(--body-bg);
  padding: 5px 10px;
  border-radius: 3px; */
}
.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .price-action-wrapper .price-container h2 {
  font-family: cursive;
  font-weight: bold;
  color: #535a5e;
  font-size: 20px;
  pointer-events: none;
  user-select: none;
}


.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .price-action-wrapper .actions-container {
  margin: 5px 0;
}

.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .price-action-wrapper .actions-container .actions-inner-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .price-action-wrapper .actions-container .actions-inner-container span{
  font-weight: 600;
  padding: 0 10px;
}
.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .price-action-wrapper .actions-container .actions-inner-container .action {
  padding: 7px 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .price-action-wrapper .actions-container .actions-inner-container .action.add-to-cart {
  background-color: var(--add-to-cart);
  /* border-top-left-radius: 7px;
  border-bottom-left-radius: 7px; */
  border-radius: 4px;
}

.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .price-action-wrapper .actions-container .actions-inner-container .action.quick-view {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: var(--button-bg);
}

.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .price-action-wrapper .actions-container .actions-inner-container .action:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  transform: translate(-2px, -2px);
}

.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .price-action-wrapper .actions-container .actions-inner-container .action i {
  color: var(--icon-light);
}


.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .tags-container {
  /* padding-bottom: 20px; */
  text-align: right;
  justify-content: flex-start;
  display: flex;
  padding: 15px 0;
}

.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .tags-container .tags-inner-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .tags-container .tags-inner-container .tag {
  border-radius: 20px;
  color: var(--text-light);
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 5px; */
  width: max-content;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease-in-out;
}

.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .tags-container .tags-inner-container .tag span {
  padding: 8px 10px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
}

.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .tags-container .tags-inner-container .tag.product-from {
  background-color: #C82021;
}

.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .tags-container .tags-inner-container .tag.type {
  background-color: #7556F2;
}
.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .title-like-container{
  display: flex;
    justify-content: space-between;
    align-items: center;
}
.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .product-title-container{
  display: flex;
  align-items: center;
  /* gap: 15px; */
  /* justify-content: space-between; */
  /* padding: 15px 0; */
}

.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .product-title-container .checkbox-num-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .product-title-container .product-name {
  font-size: 25px;
  /* line-height: 1.6; */
  /* border-bottom: solid 2px #00000008; */
  margin-bottom: 0;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .product-title-container label{
  cursor: pointer;
}
/* .product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .product-title-container .pro-num{font-weight: 600;} */

:is(.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body) .product-title-container .product-name:hover {
  color: var(--text-theme-hover);
}

:is(.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body)  .product-title-container .product-name::after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  position: absolute;
  background: var(--nav-active-hover);
  transition: width 0.2s ease 0s, left 0.2s ease 0s;
  width: 0;
}
.action i.fa-plus{transition: all 0.3s ease-in-out;transform: rotate(270deg);}
.action.added i.fa-plus{transition: all 0.3s ease-in-out; transform: rotate(45deg);color: var(--text-danger)!important;}
:is(.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body,.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper .detail-card-description-body ) .product-title-container .product-name:hover::after {
  width: 100%;
}

 .social-links-container {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* gap: 20px; */
  transition: all 0.3s ease-in-out;
  flex-wrap: wrap;
  padding: 20px 0;
}
.social-links-container .social-links:nth-child(2){
  margin: 0 10px;
}
 .social-links-container .social-links {
  display: flex;
  align-items: center;
  /* gap: 5px; */
  background-color: #EEE;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  /* opacity: 0.7; */
  padding: 0 4px;
  font-size: 13px;
  height: 33px;
  cursor: pointer;
  border: unset;
  /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.09); */
  box-shadow: 0 3px 1px -2px #0003, 0 1px 2px 0 #00000024, 2px 1px 5px 0 #0000001f;
}
 .social-links-container .social-links a{
  text-decoration: none;
  color: #000;
}
 .social-links-container .social-links  :is(span,i) {
  padding: 10px 6px;
}
.social-links-container .social-links  svg{
  padding-left: 6px;
}
 .social-links-container .social-links .checkbox-wrapper-custom-sm{padding: 0 8px;}
 .social-links-container .social-links span{cursor: pointer;font-size: 13px;font-weight: bolder;user-select: none;}
 .social-links-container .social-links:hover{
  transition: all 0.3s ease-in-out;
  background-color: var(--social-links-bg);
  opacity: 1;
}
 .social-links-container .social-links label{cursor: pointer;}
 .social-links-container .social-links:hover :is(span,i,svg){color: #fff;}
.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .description p {
  font-size: 14px;
  /* color: #535a5e; */
  margin-bottom: 0;
  width: 100%;
  /* color: #777; */
  color: #212124;
}
.product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .description.no-images  img{display: none;}


/* PRODUCT CARD LIST VIEW  (view-2 card) END*/

/* PRODUCT CARD Detail VIEW  (view-3 card) START*/
.product-detail-card-wrapper {
  margin: 15px 10px;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
  border-radius: 3px;
  /* overflow: hidden; */
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.17);
}
.product-detail-card-wrapper .product-detail-card-inner-wrapper{
  display: flex;
  transition: all 0.3s ease-in-out;
}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-gallery-wrapper{
  user-select: none;
  -webkit-user-select: none;
  transition: all 0.3s ease-in-out;
}
/* .product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-gallery-wrapper  .product-detail-card-inner-wrapper .detail-card-gallery-wrapper .product-img-slider-wrapper{

} */

.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-gallery-wrapper .product-img-slider-wrapper .product-img-slider-inner-wrapper i{
  display: block;
  z-index: 10;
  padding: 10px;
}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-gallery-wrapper .product-img-slider-wrapper .product-img-slider-inner-wrapper .btnPrev{
  left: 3%;
}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-gallery-wrapper .product-img-slider-wrapper .product-img-slider-inner-wrapper .btnNext{
  right: 3%;
}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-gallery-wrapper .product-img-slider-wrapper{
  width: 472px;
  height: 600px;
  /* height: auto; */
  min-height: 300px;
  transition: all 0.3s ease-in-out;
  position: relative;

}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-gallery-wrapper .product-img-slider-wrapper .gallery-overlay{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

 .gallery-popup-btn{
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(-20px, -20px);
  color: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: var(--open-gallery-btn-bg);
  border: 1px solid var(--open-gallery-btn-border);
  z-index: 10;
  display: flex;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 1px 2px 0px rgba(0,0,0,0.14), 2px 1px 5px 0px rgba(0,0,0,0.12);
}
.product-img-slider-inner-wrapper .gallery-popup-btn{
  display: none;
  padding: 5px;
  transform: translate(-3px, -3px);
}
.product-img-slider-inner-wrapper:hover .gallery-popup-btn{
  display: block;
}
.product-img-slider-inner-wrapper .gallery-popup-btn :is(i,svg){
  display: block;
  font-size: 14px;
}

.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-gallery-wrapper .product-img-slider-wrapper:hover .gallery-popup-btn{
  transition: all 0.3s ease-in-out;
  
}
/* .product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-gallery-wrapper .product-img-slider-wrapper .gallery-popup-btn:hover{
  background-color: #000;
  color: var(--text-light);
  border: 1px solid var(--add-to-cart);
} */
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-gallery-wrapper .product-img-slider-wrapper .gallery-popup-btn:hover i{
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;

}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-gallery-wrapper .product-img-slider-wrapper .product-img-slider-inner-wrapper img {
  border-top: 4px solid #000;
  object-fit: unset;
}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper{
  margin-left: 25px;
  width: -webkit-fill-available;
  width: -moz-available;
  width: -o-available;
  width: -ms-available;
  flex: 1;

}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper{
  border-top: 4px solid #000;
  padding: 0 20px 6px 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;

}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper .detail-card-description-body{
  transition: all 0.3s ease-in-out;
}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper .detail-card-description-body p.product-title{
  color: var(--para-text-color);
  font-weight: 600;
  margin-bottom: 0.4rem;
  line-height: 1.4;
}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper .detail-card-description-body .product-title-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 10px;
}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper .detail-card-description-body .product-title-container .product-name{
  /* padding-top: 20px; */
  margin-bottom:0;
  position: relative;
  cursor: default;
}

/* .product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper .detail-card-description-body .product-title-container .product-title::after{

} */
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper .detail-card-description-body .product-title-container .product-title label{
  cursor: pointer;
}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper .full-detail-footer .social-links-container{
  padding: 10px 0;
  justify-content: flex-end;
}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper .full-detail-footer .intreset-wrapper{
  flex-wrap: wrap;
}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper .full-detail-footer button.intreset-btn{
  margin: 5px 0 0 10px;
}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper .full-detail-footer .social-links-container .social-links:not(:nth-child(2)){margin: 5px 0;}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper .full-detail-footer .social-links-container .social-links{
  /* background-color: #fff; */
  color: #000;
  border-color: #e6e6e6;
  border: unset;
    /* box-shadow: 0 3px 1px -2px #0003, 0 1px 2px 0 #00000024, 2px 1px 5px 0 #0000001f; */
}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper .full-detail-footer .social-links-container .social-links:is(:hover,.active){
  background-color: var(--social-links-bg);
  opacity: 1;
  color: #fff;
}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper .detail-card-description-body .reference-projects-wrapper{
  padding: 5px 0;
  transition: all 0.3s ease-in-out;

}
 .field-wrapper{
  padding: 5px 2px;
}
.field-wrapper.login-password {position: relative;}
.field-wrapper.login-password i{
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(-50%, -100%);
  cursor: pointer;
}
/* .field-wrapper label{font-weight: 500;} */
.field-wrapper :is(input,textarea)::placeholder {
  opacity: 0.5;
}
.comment-wrapper :is(input,textarea)::placeholder {
  opacity: 0.5;
}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper .detail-card-description-body .description{
  padding: 0 0 5px;
  transition: all 0.3s ease-in-out;
}
.comment-wrapper{
  transition: all 0.3s ease-in-out;
}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper .full-detail-footer .comment-wrapper .interest-type-wrapper {
  display: inline-flex;
  flex-direction: row;
  transition: all 0.2s ease-in-out;
}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper .full-detail-footer .comment-wrapper .interest-type-wrapper label{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper .full-detail-footer .comment-wrapper .interest-type-wrapper .form-check-input{
  margin-right: 3px;
  border-color: hsl(0, 0%, 80%);
}
.form-check-input:checked{
  background-color: var(--theme-bg)!important;
  border-color: var(--theme-bg)!important;
}
.comment-wrapper .interest-type-wrapper .form-check-input:focus{
  box-shadow: 0 0 0 0.1rem rgba(13,110,253,.25)!important;
}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper .full-detail-footer .comment-wrapper .interest-type-wrapper label{
  user-select: none;
  transition: all 0.2s ease-in-out;
}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper .full-detail-footer .comment-wrapper .interest-type-wrapper label i{
  margin: 0 5px;
  transition: all 0.2s ease-in-out;
}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper .full-detail-footer .comment-wrapper .interest-type-wrapper input:checked ~ label i{
  color: var(--intreseted-icon-text);
}
.product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper .full-detail-footer .comment-wrapper .interest-type-wrapper input:checked ~ label i.fa-heart{
  color: var(--like-icon-text);
} 
.comment-wrapper .select-feeback-wrapper{
  padding: 5px 0;
}
.comment-wrapper textarea{
  width: 100%;
  border-radius: 4px;
  min-height: 37px;
  max-height: 200px;
  /* height: 20px; */
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: var(--bs-body-color);
  appearance: none;
  border: var(--bs-border-width) solid var(--bs-border-color);
  transition: height 0.4s ease-in-out;
  margin-top: 5px;
  min-height: 38px;
    outline: 0!important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
}
.custom-input__control{
  transition: all 0.1s ease-in-out;
}
.custom-input__control:is(:focus,:active){
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe!important;
  outline: 0!important;
  box-shadow: 0 0 0 0.15rem rgba(13,110,253,.25);
  border-radius: 0;
  transition: all 0.3s ease-in-out;
  border: 0;
}
 .comment-wrapper textarea:is(:focus,:focus-visible){
  /* color: var(--bs-body-color); */
    background-color: var(--bs-body-bg);
    /* border-color: transparent; */
    outline: 2px solid #3f8bf7;
    /* box-shadow: 0 0 0 0.15rem rgba(13,110,253,.25); */
    /* border-radius: 0; */
    /* height: 70px; */
    height: 100px;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 0 0.15rem rgba(13,110,253,.25)!important;
    background-color: var(--bs-body-bg);
    border-color: #86b7fe;

}
/* .product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper .comment-wrapper textarea:focus + .submit-btn-wrapper button{
  transform: translateY(0);
  opacity: 1;
  z-index: unset;
} */

 .submit-btn-wrapper{
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
  transition: all 0.3s ease-in-out;
}
:is(.submit-btn-wrapper button,button.btn-load-more){
  background-color: var(--submit-button-bg);
  color: #fff;
  border-radius: 3px;
  /* font-size: 12px; */
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  /* transform: translateY(-43px);
  opacity: 0;
  z-index: -99; */
  box-shadow: 0 3px 1px -2px #0003, 0 1px 2px 0 #00000024, 2px 1px 5px 0 #0000001f;
  height: 38px;
  display: flex;
    justify-content: center;
    align-items: center;
}
:is(.submit-btn-wrapper button:hover,button.btn-load-more:hover){
  color: #000;
  border: 1px solid #000;
  box-shadow: 0 0.5rem 0.8rem rgba(0, 0, 0, 0.4);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  background-color: #fff!important;
}

  /* Product VIEW 4 START */
  .intreset-wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 5px 0;
    flex-wrap: wrap;
    gap: 10px;
  }
  .intreset-wrapper .intreset-btn {
    border-radius: 4px;
    width: 175px;
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
    justify-content: center;
    flex-direction: row;
    border: 1px solid #ddd; padding: 6px; transition: all 0.3s ease-in-out; background-color: #fff; cursor: pointer; margin-left: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.09);
  }
  .intreset-wrapper .intreset-btn :is(i,svg){padding: 2px 5px 0;}
  .intreset-wrapper .intreset-btn svg{padding: 0 5px;    font-size: 18px;}
  .intreset-wrapper .intreset-btn span{font-weight: 600;}
  .intreset-wrapper .intreset-btn:is(:hover,.active){
    background-color: var(--intreset-btn);
    color: var(--text-light);
    transition: all 0.2s ease-in-out;
  }
  .intreset-wrapper .intreset-btn.active.intreseted i{
    color: var(--intreseted-icon-text);
  }
  .intreset-wrapper .intreset-btn.active.love-it i{
    color: var(--like-icon-text);
  }
  .require-info-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 0;
  }
  .require-info-wrapper .require-info-inner-wrapper{
    padding: 5px 0;
    /* background-color: #fff; */
    border-radius: 3px;
    /* width: 320px; */
  }
  .require-info-wrapper .require-info-inner-wrapper .require-title{
    font-weight: 600;
  }
  .require-info-wrapper .require-info-inner-wrapper .require-info-fields-container{
    display: flex;
    align-items: center;
  }
  .require-info-wrapper .require-info-inner-wrapper .require-info-fields-container .require-info-filed-wrapper{
    display: flex;
    align-items: flex-end;
  }
  .require-info-wrapper .require-info-inner-wrapper .require-info-fields-container .require-info-filed-wrapper label.req-info-label {
    padding: 0 10px;
  }
  .require-info-wrapper .require-info-inner-wrapper .require-info-fields-container .require-info-filed-wrapper .checkbox-wrapper-custom .back{
    line-height: 13px;
  }
  /* Product VIEW 4 END */
  
/* QUICK-VIEW START */
.quick-view-wrapper{
  height: 100%;
}
.quick-view-wrapper  + .closer {color: #fff;}
.quick-view-wrapper  + .close::before , .quick-view-wrapper  + .close::after {
  border-color: #fff!important;
  text-shadow: 0 0 10px black;
  filter: drop-shadow(0px 2px 7px black);
  }


/* .quick-view-wrapper .quick-view-inner-wrapper {
  display: flex;
} */


.quick-view-wrapper .quick-view-inner-wrapper .product-img-slider-wrapper {
  width: 100%;
  max-height: 40dvh;
  border-bottom: 1px solid #ddd;
}

.quick-view-wrapper .quick-view-inner-wrapper .product-img-slider-wrapper .product-img-slider-inner-wrapper img,
.quick-view-wrapper .quick-view-inner-wrapper .product-img-slider-wrapper .product-img-slider-inner-wrapper {
  border-radius: 0;
  display: flex;
  /* align-items: center; */
  /* background-color: #fff; */
}
.quick-view-wrapper .quick-view-inner-wrapper .product-img-slider-wrapper .product-img-slider-inner-wrapper .zoom-in-btn-container{
  display: none;
}
.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper {
  width: 100%;
  overflow-y: scroll;
  height: 40dvh;
  scrollbar-width: thin;
}
.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper::-webkit-scrollbar {
  width: 6px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper::-webkit-scrollbar-track{background: #dedada;}
.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper::-webkit-scrollbar-thumb{background: #b0aeae;}
.quick-view-wrapper .quick-view-inner-wrapper .product-img-slider-wrapper .product-img-slider-inner-wrapper i{display: block;}
.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper {
  padding: 20px;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper .social-links-container{
  justify-content: flex-end;
}
.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper .quick-view-title-container .price-title {
  font-size: 18px;
  margin-bottom: 5px;
  font-family: cursive;
  font-weight: bold;
  color: #535a5e;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper .quick-view-title-container {
  padding: 10px 0;
  position: sticky;
  top: 0;
  background-color: #fff;
}

.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper .quick-view-title-container .quick-view-title {
  position: relative;
  display: inline-block;
  font-size: 1.6rem;
}

.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper .quick-view-title-container .quick-view-title::after {
  content: '';
  position: absolute;
  top: calc(100%);
  left: 0;
  height: 2px;
  width: 40%;
  background-color: #00000008;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
}

.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper .quick-view-title-container .quick-view-title:hover::after {
  width: 100%;
  background-color: #00B5FF;
}

/* .quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper .quick-description {
  height: 300px;
  overflow-y: scroll;
  padding: 10px 5px 10px 0;
  scroll-behavior: smooth;
  -webkit-mask-image: linear-gradient(to top, transparent 5px, #000 40px);
  mask-image: linear-gradient(to top, transparent 5px, #000 40px);
} */

.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper .quick-description p {
  color: #777;
  margin-bottom: 1rem;
  letter-spacing: -.015em;
  font-size: 14px;
}

.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper .quick-tags {
  text-align: right;
  justify-content: flex-end;
  display: flex;
  padding: 10px 5px;
}

.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper .quick-tags .tags-inner-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper .quick-tags .tags-inner-container .tag {
  border-radius: 20px;
  color: var(--text-light);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  width: max-content;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease-in-out;
}

.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper .quick-tags .tags-inner-container .tag span {
  padding: 5px 10px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
}

.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper .quick-tags .tags-inner-container .tag.product-from {
  background-color: #C82021;
}

.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper .quick-tags .tags-inner-container .tag.type {
  background-color: #7556F2;
}

.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper .quick-view-actions {
  padding: 10px 0;
}

.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper .quick-view-actions .quick-add-to-cart {
  padding: 10px;
  text-align: center;
  border: 1px solid;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}

.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper .quick-description::-webkit-scrollbar {
  height: 10px;
  width: 3px;
}

.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper .quick-description::-webkit-scrollbar-thumb {
  background: #ebebeb;
  border-radius: 10px;
  position: absolute;
}

.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper .quick-view-actions .quick-add-to-cart i.fa-list {
  transform: translateX(-200px);
  transition: all 0.4s ease-in-out;
  visibility: hidden;
}

.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper .quick-view-actions .quick-add-to-cart:hover i.fa-list {
  transform: translateX(12px);
  visibility: visible;
}

.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper .quick-view-actions .quick-add-to-cart:hover i.fa-plus {
  transform: translateY(-200px);

}

.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper .quick-view-actions .quick-add-to-cart span {
  font-weight: 600;
  padding: 0 10px;
}

.quick-view-wrapper .quick-view-inner-wrapper .quick-view-product-detail-wrapper .quick-view-product-detail-inner-wrapper .quick-view-actions .quick-add-to-cart:hover {
  background-color: #000;
  color: #fff;
}

  /* Email Supplier START */
.supplier-email-wrapper {
    /* padding: 10px 20px 10px; */ 
    padding: 1.2rem;
}

.supplier-email-wrapper .visiting-title-container{
  text-align: center;
}

.further-info-wrapper {
  /* padding: 10px 10px 10px 15px; */
  /* width: 60vw; */
  /* max-height: 100%;
  overflow-y: scroll; */
  /* padding: 10px 20px; */
  padding: 1.2rem;
  font-family: system-ui;
}
.further-info-wrapper span{
  /* color: #000; */
  color: #474747;
  font-weight: 600;
}
.further-info-wrapper form{
  display: flex;
  flex-wrap: wrap;
}
.further-info-wrapper .brand-wrapper{
  display: flex;align-items: center;justify-content: center;
  /* position: sticky;top: 0; */
  z-index: 2;background-color: #fff;width: 100%;
}
.further-info-wrapper .brand-wrapper .brand-inner-wrapper{
 /* height: 65px; */
 width: 50%;
 display: flex;
 align-items: center;
 justify-content: center;
}
.further-info-wrapper .brand-wrapper .brand-inner-wrapper img{    
  width: auto;
  height: 100%;
  /* max-height: 65px; */
  max-width: 100%;
}
.further-info-wrapper .brand-wrapper .brand-inner-wrapper .brand-title{
  margin-bottom: 0;
}
.further-info-wrapper .brand-wrapper .brand-inner-wrapper .brand-title {
  padding: 0  0 0 10px;
  font-size: 40px;
  line-height: 2;
}
.further-info-wrapper .field-wrapper{
  padding: 10px 6px;
}
.further-info-wrapper label.form-label{
  color: #6d6c6c!important;
  font-weight: 600!important;
}
.further-info-wrapper .firm-status-wrapper{
  display: flex;
  /* gap: 10px; */
  /* align-items: center; */
  flex-wrap: wrap;
}
.further-info-wrapper .firm-status-wrapper .existing-firm{
  padding: 0 15px 0 0;
  display: flex;
  align-items: center;
}
.further-info-wrapper .firm-status-wrapper .existing-firm label{
  padding: 0 5px;
  color: #474747;
  font-weight: 600;
}
  /* Email Supplier END */
.custom-input__menu::-webkit-scrollbar {
  width: 5px!important;
  /* display: none; */
}
.custom-input__control--menu-is-open{
  border: 1px solid #86b7fe;
  box-shadow: 0 0 0 0.15rem rgba(13,110,253,.15)!important;;
}
  
/* -------------Product END-------------- */

/* -------------SUGGESTION DROPDOWN START------------ */

.nameSuggest {
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  padding-inline: 0;
}

.nameSuggest input:focus{
border-bottom-right-radius: 0!important;
  border-bottom-left-radius: 0!important;
  -webkit-box-shadow: 0px -4px 4px 0px rgb(13 110 253 / 10%)!important;
  box-shadow: 0px -4px 4px 0px rgb(13 110 253 / 10%)!important;
  }

.nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown {
    position: absolute;
    top: calc(100% - 6px);
    /* width: 100%; */
    /* min-height: 50px; */
    /* max-height: 230px; */
    /* overflow-y: auto; */
    /* left: calc(100%); */
    /* background-color: #fff !important; */
    /* display: none; */
    z-index: 4;
    width: calc(100%);
    /* border-radius: 3px; */
    /* border: 1px solid #ddd; */
    padding: 0 0 34px 0;
}
.nameSuggest
.autosuggestDropDownContainer
.autosuggestDropDown
ul {
  border: 1px solid #86b7fe;
  max-height: 230px;
  overflow-y: auto;
  background-color: #fff;
  list-style-type: none;
  width: 100%;
  padding: 0;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 10px 15px -3px rgba(13,110,253,.15), 0 4px 6px -4px rgba(0,0,0,0.15);
}
.nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown
  ul
  li {
  cursor: pointer;
  margin: 5px 0;
  /* font: italic bold 16px/1.5 emoji; */
  /* font-weight: 500; */
  text-align: start;
  font-size: 16px;
  line-height: 1.5;
  padding: 6px 9px;
  border-bottom: 1px solid #ddd;
}
.nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown
  ul
  li:last-child{
    border: 0;
  }
.nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown
  ul
  li:hover {
  background-color: #ebf8fd;
  /* color: #fff; */
}

.nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown ul::-webkit-scrollbar {
  width: 5px;
  /* display: none; */
}

/* .nameSuggest
  .autosuggestDropDownContainer:hover
  .autosuggestDropDown ul::-webkit-scrollbar {
  display: block;
} */

.nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown::-webkit-scrollbar-track {
  background: #f1f1f1;
  display: none;
}

.nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown ul::-webkit-scrollbar-thumb {
  background: #b9b5b5;
  border-radius: 2px;
}

.nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown ul::-webkit-scrollbar-thumb:hover {
  background: #555;
  display: none;
}
.nameSuggest .autosuggestDropDownContainer.active {
  display: block;

}


/* -------------SUGGESTION DROPDOWN END------------ */

/* -------------CART PAGE START------------*/
.cart-wrapper .cart-inner-wrapper{
  padding: 40px 0;
}
.page-title-container{
  text-align: center;
  padding-bottom: 25px;
}
.page-title-container .page-title{
  border-bottom: 0.2rem solid var(--text-theme-hover);
  display: inline-block;
  padding-bottom: 0.3rem;
}


      /* CART ITEM CARD  START*/
.cart-item-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* align-items: center; */
  flex-wrap: wrap;
  padding: 15px 0;
}
.cart-item-wrapper .item-info-action{
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}
.cart-item-wrapper .item-info-action{
  /* width: 30%; */
  margin: 5px 0;
}
.cart-item-wrapper .item-info-action .action-wrapper svg{
  /* padding-right: 10px; */    visibility: hidden;
  cursor: pointer;
}
.cart-item-wrapper .item-info-action .action-wrapper{
  position: relative;
  /* height: 30px; */
  cursor: pointer;
}
.cart-item-wrapper .item-info-action .action-wrapper::after, .cart-item-wrapper .item-info-action .action-wrapper::before{
  content: "";
  height: 20px;
  width: 20px;
  border-top: 1px solid #000;
  position: absolute;
  top: 50%;
  left: 3px;
  transform: rotate(-45deg);
  transition: all 0.2s ease-in-out;

}
.cart-item-wrapper .item-info-action .action-wrapper::after{
  left: -11px;
  transform: rotate(45deg);
}
.cart-item-wrapper .item-info-action .action-wrapper:hover::after, .cart-item-wrapper .item-info-action .action-wrapper:hover::before{
  border-color: var(--text-danger);
}
.cart-item-wrapper .item-info-action .item-image {
  /* width: 170px;
  margin: 0 20px; */
}
.cart-item-wrapper .item-info-action .item-image img{
  width: 100%;
  height: auto;
}

.cart-item-wrapper .item-info-action .item-title-wrapper .title{
  text-transform: capitalize;
  margin: 0 10px;
}
.cart-item-wrapper .item-description-form-wrapper{
  display: flex;
  flex-wrap: wrap;
  padding: 25px 20px;
  background-color: #fff;
  border-radius: 5px;
  justify-content: space-between;
  margin: 5px 0;
}

.cart-item-wrapper .item-description-form-wrapper .item-selection-wrapper .item-single-selection{
  display:flex;
  align-items: center;
  padding: 5px 0;
}
.cart-item-wrapper .item-description-form-wrapper .item-selection-wrapper .item-single-selection .item-label{
  padding-left: 10px ;
}
.cart-item-wrapper .item-description-form-wrapper .item-description .location-selection-wrapper {
  padding-bottom: 15px;
}

.cart-item-wrapper .item-description-form-wrapper .item-description .location-selection-wrapper select{
  font-weight: 600;
}
      /* CART ITEM CARD  END*/

.cart-wrapper .cart-inner-wrapper .cart-form-wrapper {
  padding: 15px 0;
}    

.cart-wrapper .cart-inner-wrapper .cart-form-wrapper .cart-form-inner-wrapper {
    background-color: #fff;
    max-width: 80%;
    padding: 25px 15px;
    margin: 0 auto;
    border-radius: 5px;
}

.cart-wrapper .cart-inner-wrapper .cart-form-wrapper .cart-form-inner-wrapper .form-inner-container {
  display: flex;
  flex-wrap: wrap;
}
.cart-wrapper .cart-inner-wrapper .cart-form-wrapper .cart-form-inner-wrapper .form-inner-container :is(.field-wrapper,.submit-cart-btn-wrapper){
  padding-right: 15px;
  padding-left: 15px;
  /* padding: 15px 20px; */
}
.cart-wrapper .cart-inner-wrapper .cart-form-wrapper .cart-form-inner-wrapper .form-inner-container .submit-cart-btn-wrapper{
  padding: 15px 15px 0px;
}
.cart-wrapper .cart-inner-wrapper .cart-form-wrapper .cart-form-inner-wrapper .form-inner-container .submit-cart-btn-wrapper button {
  background-color: var(--checkout-button-bg);
  color: #fff;
  font-weight: 600;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.cart-wrapper .cart-inner-wrapper .cart-form-wrapper .cart-form-inner-wrapper .form-inner-container .submit-cart-btn-wrapper button:hover{
  /* background-color: var(--button-bg); */
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}


/* ---------------CART PAGE END------------ */

/* ----------ABOUT US START---------------- */
.about-us-wrapper .about-us-inner-wrapper{
  padding: 30px 0;
  font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  /* background-color: #fff; */
}
.about-us-wrapper .banner-wrapper .banner-inner-text  h2{font-size: 2.3rem;}
.about-us-wrapper .about-us-inner-wrapper .about-us-title-container .about-us-title{
  text-align: center;
  position: relative;
  display: inline-block;
  padding: 0 6px;
  /* margin-bottom: 20px; */
  font-size: 1.8rem;
  margin-bottom: 2.5rem;
  /* font-family: "PMNCaeciliaW01-85Heavy","Helvetica Neue","Helvetica",Helvetica,Arial,sans-serif; */
  font-weight: 600;
}
.about-us-wrapper .about-us-inner-wrapper .about-us-title-container{text-align: center;}
.about-us-wrapper .about-us-inner-wrapper .about-us-title-container .about-us-title::after{
  content: '';
  position: absolute;
  top: calc(100% );
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #00B5FF;
}
.about-us-wrapper .about-us-inner-wrapper .about-us-content-wrapper {
  padding: 5px 0;
}
.about-us-wrapper .about-us-inner-wrapper .about-us-content-wrapper .about-us-content-inner-wrapper{
   display: flex; gap: 30px;
   align-items: center;
   width: 75%;
   margin: 0 auto;}
/* .about-us-wrapper .about-us-inner-wrapper .about-us-content-wrapper .about-us-content-inner-wrapper :is(.about-us-body,.about-us-img){width: 50%;} */
.about-us-wrapper .about-us-inner-wrapper .about-us-content-wrapper .about-us-content-inner-wrapper  .about-us-body h2.about-us-content-title {
  /* margin-top: 1.25rem; */
  margin-bottom: 10px;
  /* font-family: "HelveticaNeueW01-75Bold","Helvetica Neue","Helvetica",Helvetica,Arial,sans-serif; */
  font-size: 1.5rem;
  font-weight: 600;
  color: #000;
}
.about-us-wrapper .about-us-inner-wrapper .about-us-content-wrapper .about-us-content-inner-wrapper .contact-us-wrapper .btn-contact-us{color: var(--text-light)!important;background-color: var(--button-bg);border-radius: 3px;font-weight: 600;}
.about-us-wrapper .about-us-inner-wrapper .about-us-content-wrapper .about-us-content-inner-wrapper .contact-us-wrapper .btn-contact-us:hover{
  background-color: #fff;
  color: #000!important;
  border-color: var(--button-bg);
}
.about-us-wrapper .about-us-inner-wrapper .about-us-content-wrapper .about-us-content-inner-wrapper .about-us-body a{
 color: var(--anchor-theme);
}
.about-us-wrapper .about-us-inner-wrapper .about-us-content-wrapper .about-us-content-inner-wrapper  .about-us-img{border-radius: 5px;
      overflow: hidden;}
.about-us-wrapper .about-us-inner-wrapper .about-us-content-wrapper .about-us-content-inner-wrapper  .about-us-img img{
  width: 100%;
}
/* ----------ABOUT US START---------------- */

/* ----------SUBMITTED STATUS WRAPPER START------- */

.form-status-wrapper .form-status-inner-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 10px;
  user-select: none;
}
.form-status-wrapper .form-status-inner-wrapper .form-status-title{
  margin: 0;
    padding: 0 15px;
    font-size: 20px;
    font-weight: 600;

}
.form-status-wrapper .form-status-inner-wrapper img {
  /* width: 40px; */
  max-width: 50px;
  height: 35px;
}
/* ----------SUBMITTED STATUS WRAPPER END------- */

.custom-divider hr {
  border-style: dashed;
}

/* ---------------PRODUCT DETAIL PAGE START--------------- */
.product-detail-page{
  margin-top: 15px;
}
.product-detail-page .desc-wrapper{ 
  display: flex;
  flex-wrap: wrap;
}
.product-detail-page .product-card-list-wrapper{
  margin: 10px 0;
}
.product-detail-page .desc-wrapper .bread-crumb{
  /* margin-top: 17px; */
  width: 100%;
  background-color: #fff;
  border: none;
  padding: 5px;
  color: var(--anchor-theme);
  display: inline-block;
  text-decoration: none;
  text-align: center;
  font-weight: 600;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.1s ease-in-out;
}
.product-detail-page .desc-wrapper .bread-crumb :is(i,svg){
  transition: all 0.1s ease-in-out;

}
.product-detail-page .desc-wrapper .bread-crumb:hover{
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
}
.product-detail-page .desc-wrapper .bread-crumb:hover :is(i,svg){
  transform: translateX(-10px);
}
.product-detail-page .product-media-wrapper{
  padding: 20px 0;
  display: flex;
}
.product-detail-page .product-media-wrapper .select-media{
  padding-top: 10px;
}
.product-detail-page .product-media-wrapper .select-media .single-file-wrapper{
  width: 100%;
  height: 15rem;
  margin: 5px 0px;
  border-radius: 5px;
  position: relative;
  border: 3px solid transparent;
}
.product-detail-page .product-media-wrapper .select-media .single-file-wrapper iframe{
  width: 100%;
  height: 100%;
}
.product-detail-page .product-media-wrapper .select-media .single-file-wrapper.selected{
  border: 3px solid #00B5FF;
  pointer-events: none;
  opacity: 0.8;
  -webkit-user-select: none;
  user-select: none;
}
.product-detail-page .product-media-wrapper .media-full-viewer{
  height: 90vh;
  width: 100%;
  margin-left: auto;
  padding: 15px;
  /* display: flex;
  justify-content: center;
  align-items: center;
  position: relative; */
}
.product-detail-page .product-media-wrapper .media-full-viewer iframe{
  width: 100%;
  height: 100%;
}

/* ---------------PRODUCT DETAIL PAGE END----------------- */

@media(min-width:576px) and (max-width:630px){
  .product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer .product-card-inner-footer .action:not(:last-child) span{display: none;}
  .product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-body .product-title-container{
    display: block;
  }
  .product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .actions-wrapper{
    justify-content: flex-end;
  }
  .product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer .product-card-inner-footer .action.feed-submitted-message .form-status-title {
   
    font-size: 1.2rem;
}
.actions-wrapper.submitted-image-wrapper {
  position: absolute;
  bottom: 8px;
  right: 9px;
}
}

@media (max-width:575px) {

  /* .product-wrapper .product-header-wrapper .product-header-inner-wrapper {
    display: block;
  }

  .product-wrapper .product-header-wrapper .product-header-inner-wrapper .product-view-type .view {
    width: 50%;
    justify-content: center;
  } */
  .product-wrapper .product-header-wrapper .product-header-inner-wrapper .product-view-type .view span {
    display: none;
  }

  .product-header-wrapper .product-header-inner-wrapper .product-filter-wrapper {
    margin-top: 15px;
    width: 100%;
  }

  .product-header-wrapper .product-header-inner-wrapper .product-filter-wrapper input {
    width: 100%;
  }

  /* .product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-header .product-img-slider-wrapper {
    height: 300px;
  } */
  .about-us-wrapper .about-us-inner-wrapper {
    width: 100% !important;
  }

  .about-us-wrapper .about-us-inner-wrapper .about-us-title-container .about-us-title::after {
    height: 0;
  }

  .about-us-wrapper .banner-wrapper .banner-inner-text h2 {
    text-align: center;
    font-size: 2.3rem;
  }
  .product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper{
    margin: 35px 5px;
  }
  .product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper:hover{
    overflow: unset;
  }
  .product-wrapper .product-overlay{
    display: none;
  }
  .product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper:is(.product-card-mobile) .product-card-footer{
    position: relative;
    z-index: unset;
    box-shadow: unset!important;
  }
  .product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .product-card-footer{
    transform: translateY(0);
    opacity: 1;
  }
  .supplier-email-wrapper .submit-btn-wrapper button[type=submit]{
    width: 100%;
  }
  .further-info-wrapper {
    padding: 1rem 0.5rem;
    font-family: system-ui;
}
.product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper :is(.product-card-inner-wrapper) .product-card-container:hover .product-card-footer{
  position: relative;
  z-index: unset;
  box-shadow: unset!important;
}
}

@media (max-width:767px) {
  section.banner-wrapper.home-banner .banner-inner-wrapper .banner-inner-text .banner-content-container {
    width: 100%;
  }

  /* .product-img-slider-wrapper {
    height: 220px;
  } */

 .further-info-wrapper{width: 100%;max-width: 100%;}

  .product-wrapper .product-header-wrapper .product-header-inner-wrapper .product-view-type .view {
    width: 50%;
    justify-content: center;
  }
  .container, .container-sm {
    max-width: 90%!important;
  }
  
  .product-img-slider-wrapper .product-img-slider-inner-wrapper :is(i,svg){
    display: block;    font-size: 30px;
  }
  .about-us-wrapper .about-us-inner-wrapper .about-us-content-wrapper .about-us-content-inner-wrapper{
    flex-direction: column;
  }
  .about-us-wrapper .about-us-inner-wrapper .about-us-content-wrapper .about-us-content-inner-wrapper :is(.about-us-body,.about-us-img){width: 90%;}
  .about-us-wrapper .about-us-inner-wrapper .about-us-content-wrapper .about-us-content-inner-wrapper .about-us-body{
    order: 2;
  }
  .about-us-wrapper .about-us-inner-wrapper .about-us-content-wrapper .about-us-content-inner-wrapper .about-us-img{
    order: 1;
  }
  .product-wrapper .products-body-wrapper .products-body-inner-wrapper .product-card-grid-wrapper .product-card-inner-wrapper .product-card-container .actions-wrapper .action{
    font-size: 20px;
  }
  .further-info-wrapper .brand-wrapper {
    border-bottom: 1px solid #ddd;
}
}

@media (max-width:768px){
  .product-wrapper .product-header-wrapper{display: none;}
  .product-card-container .product-card-header .product-img-slider-wrapper .overlay{display: none;}
  .product-img-slider-inner-wrapper .gallery-popup-btn{display: none;visibility: hidden;opacity: 0;}
}
/* @media (min-width: 768px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 80% !important;
  }
} */

@media(min-width:768px){
  /* .further-info-wrapper{
    width: 500px;
  } */
}

@media (max-width:991px) {
  /* .product-card-list-wrapper .product-card-list-inner-wrapper .product-img-slider-wrapper{width: 100%;} */
  
  .product-card-list-wrapper .product-card-list-inner-wrapper :is(.product-img-slider-wrapper, .product-card-body) {
    width: 100%;
    padding: 15px;
  }
  .product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .price-action-wrapper .actions-container{
    width: 100%;
  }
  /* .product-card-list-wrapper .product-card-list-inner-wrapper .product-card-body .price-action-wrapper .actions-container .actions-inner-container .action{
    width: 100%;
    text-align: center;
  } */
  .product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-gallery-wrapper{
    width: 100%;
  }
  .product-detail-card-wrapper .product-detail-card-inner-wrapper{
    flex-wrap: wrap;
  }
  .product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper{
    margin-top: 25px;
    width: 100%;
    margin-left: 0;
  }
  .product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-description-wrapper .detail-card-description-inner-wrapper{
   padding: 0 10px 5px 10px; 
   border-color: #fff
  }
  .cart-wrapper .cart-inner-wrapper .cart-form-wrapper .cart-form-inner-wrapper{
    max-width: 100%;
  }
  .product-detail-card-wrapper .product-detail-card-inner-wrapper .detail-card-gallery-wrapper .product-img-slider-wrapper{
    width: 100%;
  }
  .product-wrapper .products-body-wrapper .products-body-inner-wrapper div.col-12:not(:last-child) .product-detail-card-wrapper{
    /* border-top: unset; */
    border-bottom: 1px dashed #bbbaba;
    padding-bottom: 3px;
    margin: 10px;
  }
  .form-wrapper-view-3{order: 2;}
  .intresets-btn-wapper{order: 1;}
  .about-us-wrapper .about-us-inner-wrapper .about-us-content-wrapper .about-us-content-inner-wrapper{width: 95%;align-items: center;}
  .further-info-wrapper .field-wrapper{
    padding: 10px 6px;
  }
  .rep-cover-wrapper .rep-cover-inner-wrapper .rep-profile-container{
    display: block;
  }
  .rep-cover-wrapper .rep-cover-inner-wrapper .rep-profile-container .rep-contact-wrapper{
    justify-content: flex-end;
    padding-top: 10px;
  }
}

@media (max-width:1024px) {
  section.banner-wrapper.home-banner .banner-inner-wrapper .banner-inner-text .banner-content-container h2 {
    font-size: 35px;
  }
}

/* @media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1050px !important;
  }
} */


@media (min-width:768px) and (max-width:1100px) {
  .product-img-slider-wrapper {
    height: 250px;
  }
}

/* ANITMATIONS */




/* .swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #000;
}

.swiper-slide img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
} */
