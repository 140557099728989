.wrapper {
    width: 50px;
    height: 20px;
    position: relative;
    z-index: 1;
  }
  
  .circle {
    width: 7px;
    height: 7px;
    position: absolute;
    border-radius: 50%;
    background-color: #000;
    left: 15%;
    transform-origin: 50%;
    animation: circle7124 .5s alternate infinite ease;
  }
  
  @keyframes circle7124 {
    0% {
      top: 15px;
      height: 5px;
      /* border-radius: 50px 50px 25px 25px; */
      /* transform: scaleX(1.7);s */
    }
  
    40% {
      /* height: 20px; */
      border-radius: 50%;
      /* transform: scaleX(1); */
    }
  
    100% {
      top: 0%;
    }
  }
  
  .circle:nth-child(2) {
    left: 40%;
    animation-delay: .1s;
  }
  
  .circle:nth-child(3) {
    left: auto;
    right: 20%;
    animation-delay: .2s;
  }
  
  .shadow {
    width: 10px;
    height: 4px;
    border-radius: 50%;
    background-color: rgba(0,0,0,0.9);
    position: absolute;
    top: 18px;
    transform-origin: 50%;
    z-index: -1;
    left: 15%;
    filter: blur(1px);
    animation: shadow046 .5s alternate infinite ease;
  }
  
  @keyframes shadow046 {
    0% {
      transform: scaleX(1);
    }
  
    40% {
      transform: scaleX(1);
      opacity: .7;
    }
  
    100% {
      transform: scaleX(1);
      opacity: .4;
    }
  }
  
  .shadow:nth-child(4) {
    left: 45%;
    animation-delay: .2s
  }
  
  .shadow:nth-child(5) {
    left: auto;
    right: 15%;
    animation-delay: .3s;
  }
  